import React, { useState } from "react";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import FormInput from "../FormInput";
import Button from "../../components/Button";
import SubscriptionAPI from "../../apis/subscriptionAPI";
import TextArea from "../TextArea";

export default function AddSubscriptionModal({ show, toggle }) {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [intervals, setIntervals] = useState("");
  const [duration, setDuration] = useState("");
  const [nameError, setNameError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [intervalsError, setIntervalsError] = useState(false);
  const [durationError, setDurationError] = useState(false);

  const validateFields = () => {
    if (name.trim().length === 0) {
      setNameError(true);
      return;
    }

    if (type.trim().length === 0) {
      setTypeError(true);
      return;
    }

    if (description.trim().length === 0) {
      setDescriptionError(true);
      return;
    }

    if (price.trim().length === 0) {
      setPriceError(true);
      return;
    }

    if (intervals.trim().length === 0) {
      setIntervalsError(true);
      return;
    }

    if (duration.trim().length === 0) {
      setDescriptionError(true);
      return;
    }

    createSubscription();
  };

  const createSubscription = async () => {
    setLoading(true);
    let data = {
      name,
      type,
      description,
      price,
      intervals,
      duration,
    };

    try {
      await SubscriptionAPI.createSubscription(data);
      setName("");
      setType("");
      setDescription("");
      setPrice("");
      setIntervals("");
      setDuration("");
      Toast.success("Subscription Added Successfully");
    } catch (err) {
      Toast.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={
        show
          ? "w-full bg-black bg-opacity-20 fixed z-30 inset-0"
          : "hidden w-full bg-black bg-opacity-20 fixed z-30 inset-0"
      }
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        id="staticModal"
        data-modal-backdrop="static"
        tabindex="-1"
        aria-hidden="true"
        className="max-w-2xl mt-20 overflow-y-auto overflow-x-hidden fixed z-50 p-4 w-full md:inset-0 h-modal mx-auto md:h-full"
      >
        <div className="relative w-full max-w-2xl h-full md:h-auto">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow">
            {/* Modal header */}
            <div className="flex items-start p-4 rounded-t">
              <button
                onClick={() => toggle()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-red-500 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                data-modal-toggle="staticModal"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="px-6 pb-4">
              <div className="md:grid grid-cols-2 gap-x-5 gap-y-2">
                <FormInput
                  id="name"
                  label="Subscription Name"
                  placeholder="Subscription Name"
                  required={true}
                  type="text"
                  value={name}
                  error={nameError}
                  errorMessage="Please enter the subscription name"
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(false);
                  }}
                />
                <FormInput
                  id="type"
                  label="Subscription Type"
                  placeholder="Subscription Type"
                  required={true}
                  type="text"
                  value={type}
                  error={typeError}
                  errorMessage="Please the subscription type"
                  onChange={(e) => {
                    setType(e.target.value);
                    setTypeError(false);
                  }}
                />
                <TextArea
                  id="description"
                  label="Subscription description"
                  placeholder="Subscription description"
                  required={true}
                  value={description}
                  error={descriptionError}
                  errorMessage="Please the subscription description"
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDescriptionError(false);
                  }}
                />
                <FormInput
                  id="price"
                  label="Price"
                  placeholder="3000"
                  required={true}
                  type="number"
                  value={price}
                  error={priceError}
                  errorMessage="Please enter the price per kilometer"
                  onChange={(e) => {
                    setPrice(e.target.value);
                    setPriceError(false);
                  }}
                />
                <FormInput
                  id="intervals"
                  label="Number of pickups per week"
                  placeholder="2"
                  required={true}
                  type="number"
                  value={intervals}
                  error={intervalsError}
                  errorMessage="Please enter the number pickups per week"
                  onChange={(e) => {
                    setIntervals(e.target.value);
                    setIntervalsError(false);
                  }}
                />
                <FormInput
                  id="duration"
                  label="Duration in days"
                  placeholder="30"
                  required={true}
                  type="number"
                  value={duration}
                  error={durationError}
                  errorMessage="Please enter the price per waste bag"
                  onChange={(e) => {
                    setDuration(e.target.value);
                    setDescriptionError(false);
                  }}
                />
              </div>
              <Button
                onClick={validateFields}
                disabled={false}
                isLoading={loading}
                label="Add"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
