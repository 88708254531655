import React, { useState } from "react";
import AdminAPI from "../../apis/adminAPI";
import { Link, useNavigate } from "react-router-dom";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import {
  HiHome,
  HiChatBubbleLeftEllipsis,
  HiEnvelope,
  HiUser,
  HiCreditCard,
  HiBanknotes,
  HiAdjustmentsHorizontal,
  HiArrowLeftOnRectangle,
  HiClipboardDocumentCheck,
  HiUserGroup,
} from "react-icons/hi2";
import FormInput from "../../components/FormInput";
import TextArea from "../../components/TextArea";
import Button from "../../components/Button";
import LogoutModal from "../../components/modals/LogoutModal";
import NotificationSideBar from "../../components/NotificationSideBar";

export default function PromotionalMessages() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [types, setTypes] = useState([]);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [recipientsError, setRecipientsError] = useState(false);
  const [typesError, setTypesError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [bodyError, setBodyError] = useState(false);

  const validateFields = () => {
    if (recipients.length === 0) {
      setRecipientsError(true);
      return;
    }

    if (types.length === 0) {
      setTypesError(true);
      return;
    }

    if (title.length === 0) {
      setTitleError(true);
      return;
    }

    if (body.length === 0) {
      setBodyError(true);
      return;
    }
    sendMessage();
  };

  const sendMessage = async () => {
    setLoading(true);
    let data = {
      recipients,
      types,
      title,
      body,
    };

    try {
      let response = await AdminAPI.sendMessage(data);
      Toast.success(response.message);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full md:w-10/12 h-screen overflow-auto">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin - Trash4Me</title>
      </Helmet>
      <LogoutModal
        show={showLogout}
        toggle={() => setShowLogout(!showLogout)}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* Notification SideBar */}
      <NotificationSideBar
        show={showNotification}
        toggle={() => setShowNotification(!showNotification)}
      />

      <div className="flex items-center space-x-3 border-b py-5">
        <HiEnvelope size="30" className="text-lime-500" />
        <p className="font-bold text-lg">Promotional Messages</p>
        <div className="grow"></div>
        <button
          type="button"
          onClick={() => {
            document.querySelector("#mobile-menu").classList.toggle("hidden");
          }}
          className="inline-flex items-center py-2 md:px-5 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          onClick={() => setShowNotification(!showNotification)}
          className="inline-flex items-center py-2 pr-5 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-7 h-7"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.7029 24H8.29707C5.85922 24 4.46766 21.5478 5.93036 19.8295C6.5779 19.0687 6.97696 18.1665 7.08435 17.2203L7.6806 11.9669C8.00211 9.13414 10.1169 6.80955 12.9734 5.83151V5.69322C12.9734 4.02168 14.3284 2.66663 16 2.66663C17.6715 2.66663 19.0266 4.02168 19.0266 5.69322V5.83151C21.8831 6.80955 23.9979 9.13414 24.3194 11.9669L24.9156 17.2203C25.023 18.1665 25.4221 19.0687 26.0696 19.8295C27.5323 21.5478 26.1407 24 23.7029 24ZM19.9628 26.762C19.353 28.2655 17.8087 29.3333 16 29.3333C14.1913 29.3333 12.647 28.2655 12.0372 26.762C12.0117 26.6993 12 26.632 12 26.5643C12 26.2526 12.2527 26 12.5644 26H19.4356C19.7473 26 20 26.2526 20 26.5643C20 26.632 19.9882 26.6993 19.9628 26.762Z"
              fill="#28303F"
            />
          </svg>
        </button>
        <div
          className="absolute z-10 top-16 right-3 bg-gray-100 w-max hidden md:w-auto"
          id="mobile-menu"
        >
          <ul className="flex flex-col p-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-lg md:font-medium text-lime">
            <li>
              <Link
                to={{
                  pathname: "/admin/dashboard",
                }}
                className="group sidebar-icon"
              >
                <HiHome size="20" className="mr-2" />
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/users", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiUser size="20" className="mr-2" />
                Users
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/staff", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiUserGroup size="20" className="mr-2" />
                Staff
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/admin/requests",
                  search: "?page=1&status=All",
                }}
                className="group sidebar-icon"
              >
                <HiClipboardDocumentCheck size="20" className="mr-2" />
                Requests
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/admin/user-subscriptions",
                  search: "?page=1",
                }}
                className="group sidebar-icon"
              >
                <HiChatBubbleLeftEllipsis size="20" className="mr-2" />
                User Subscriptions
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/subcriptions" }}
                className="group sidebar-icon"
              >
                <HiCreditCard size="20" className="mr-2" />
                Subcriptions
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/payment-history", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiBanknotes size="20" className="mr-2" />
                Payment History
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/promotional" }}
                className="group sidebar-icon"
              >
                <HiEnvelope size="20" className="mr-2" />
                Promotional Messages
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/settings" }}
                className="group sidebar-icon"
              >
                <HiAdjustmentsHorizontal size="20" className="mr-2" />
                Settings
              </Link>
            </li>
            <li>
              <button
                onClick={() => setShowLogout(!showLogout)}
                className="logout-icon group"
              >
                <HiArrowLeftOnRectangle size="20" className="mr-2" />
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="">
        <div className="md:flex space-x-5">
          <div className="mr-5 md:mr-0 md:w-96 mt-5">
            <p className="text-base leading-relaxed text-gray-500">
              Send Emails, Push Notifications and SMS Messages to Users and
              Staff
            </p>
          </div>
          <div className="grow pr-10">
            <form className="">
              {/* to checkbox */}
              <div className="mt-5">
                <h3 className="mb-4 font-semibold text-gray-900">Recipients</h3>
                <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:flex">
                  <li className="w-full border-b border-gray-200 md:border-b-0 md:border-r">
                    <div className="flex items-center pl-3">
                      <input
                        id="all-users"
                        type="checkbox"
                        onChange={(e) => {
                          setRecipientsError(false);
                          let data = recipients;
                          if (e.target.checked) {
                            data.push("Users");
                            setRecipients(data);
                          } else {
                            const index = data.indexOf("Users");
                            data.splice(index, 1);
                            setRecipients(data);
                          }
                        }}
                        value={recipients.includes("Users")}
                        className="w-4 h-4 text-lime-500 bg-gray-50 rounded border border-gray-300  focus:ring-3 focus:ring-lime-300"
                      />
                      <label
                        for="all-users"
                        className="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        All Users
                      </label>
                    </div>
                  </li>
                  <li className="w-full border-b border-gray-200 md:border-b-0 md:border-r">
                    <div className="flex items-center pl-3">
                      <input
                        id="all-staff"
                        type="checkbox"
                        onChange={(e) => {
                          setRecipientsError(false);
                          let data = recipients;
                          if (e.target.checked) {
                            data.push("Staff");
                            setRecipients(data);
                          } else {
                            const index = data.indexOf("Staff");
                            data.splice(index, 1);
                            setRecipients(data);
                          }
                        }}
                        value={recipients.includes("Staff")}
                        className="w-4 h-4 text-lime-500 bg-gray-50 rounded border border-gray-300  focus:ring-3 focus:ring-lime-300"
                      />
                      <label
                        for="all-staff"
                        className="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        All Staff
                      </label>
                    </div>
                  </li>
                </ul>
                {recipientsError ? (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    Please at least one type of recipient
                  </p>
                ) : (
                  <></>
                )}
              </div>

              {/* type checkbox */}
              <div className="mt-5">
                <h3 className="mb-4 font-semibold text-gray-900">
                  Type of Message
                </h3>
                <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:flex">
                  <li className="w-full border-b border-gray-200 md:border-b-0 md:border-r">
                    <div className="flex items-center pl-3">
                      <input
                        id="email"
                        type="checkbox"
                        onChange={(e) => {
                          setTypesError(false);
                          let data = types;
                          if (e.target.checked) {
                            data.push("email");
                            setTypes(data);
                          } else {
                            const index = data.indexOf("email");
                            data.splice(index, 1);
                            setTypes(data);
                          }
                        }}
                        value={recipients.includes("email")}
                        className="w-4 h-4 text-lime-500 bg-gray-50 rounded border border-gray-300  focus:ring-3 focus:ring-lime-300"
                      />
                      <label
                        for="email"
                        className="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        Email
                      </label>
                    </div>
                  </li>
                  <li className="w-full border-b border-gray-200 md:border-b-0 md:border-r">
                    <div className="flex items-center pl-3">
                      <input
                        id="push-notification"
                        type="checkbox"
                        onChange={(e) => {
                          setTypesError(false);
                          let data = types;
                          if (e.target.checked) {
                            data.push("push-notification");
                            setTypes(data);
                          } else {
                            const index = data.indexOf("push-notification");
                            data.splice(index, 1);
                            setTypes(data);
                          }
                        }}
                        value={recipients.includes("push-notification")}
                        className="w-4 h-4 text-lime-500 bg-gray-50 rounded border border-gray-300  focus:ring-3 focus:ring-lime-300"
                      />
                      <label
                        for="push-notification"
                        className="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        Push Notification
                      </label>
                    </div>
                  </li>
                  <li className="w-full border-b border-gray-200 md:border-b-0 md:border-r">
                    <div className="flex items-center pl-3">
                      <input
                        id="SMS"
                        type="checkbox"
                        onChange={(e) => {
                          setTypesError(false);
                          let data = types;
                          if (e.target.checked) {
                            data.push("SMS");
                            setTypes(data);
                          } else {
                            const index = data.indexOf("SMS");
                            data.splice(index, 1);
                            setTypes(data);
                          }
                        }}
                        value={recipients.includes("SMS")}
                        className="w-4 h-4 text-lime-500 bg-gray-50 rounded border border-gray-300  focus:ring-3 focus:ring-lime-300"
                      />
                      <label
                        for="SMS"
                        className="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        SMS
                      </label>
                    </div>
                  </li>
                </ul>
                {typesError ? (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    Please at least one type of message
                  </p>
                ) : (
                  <></>
                )}
              </div>

              <FormInput
                id="title"
                label="Title"
                type="text"
                placeholder="Message Title"
                required={true}
                value={title}
                error={titleError}
                errorMessage="Please enter a title for your message"
                onChange={(e) => {
                  setTitle(e.target.value);
                  setTitleError(false);
                }}
              />
              <TextArea
                id="body"
                label="Body"
                type="text"
                placeholder="Message Body"
                value={body}
                required={true}
                error={bodyError}
                errorMessage="Please enter a message body"
                onChange={(e) => {
                  setBody(e.target.value);
                  setBodyError(false);
                }}
              />
            </form>
            <Button
              onClick={validateFields}
              disabled={false}
              isLoading={loading}
              label="Send"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
