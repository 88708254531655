import React, { useState, useEffect } from "react";
import moment from "moment";
import Button from "../Button";
import DangerButton from "../DangerButton";
import SelectInput from "../SelectInput";
import StaffAPI from "../../apis/staffAPI";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminAPI from "../../apis/adminAPI";

export default function RequestModal({ show, toggle, request }) {
  const [loading, setLoading] = useState(false);
  const [assignToStaff, setAssignToStaff] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [staff, setStaff] = useState(null);
  const [selectedStaffError, setSelectedStaffError] = useState(false);

  const getStaff = async (perPage, page, name) => {
    try {
      let response = await StaffAPI.getStaffs({ perPage, page, name });
      setStaff(response.data);
    } catch (err) {
      Toast.error(err.response.data.message);
    }
  };

  const assignRequestToStaff = async () => {
    if (!selectedStaff) {
      setSelectedStaffError(true);
      return;
    }

    setLoading(true);
    let data = { staffId: selectedStaff._id, requestId: request._id };

    try {
      await AdminAPI.assignRequestToStaff(data);
      request.staffId = selectedStaff;
      setAssignToStaff(false);
      Toast.success(`Request Assigned to ${selectedStaff.firstName}`);
    } catch (err) {
      Toast.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStaff(100, 1, "");
  }, []);

  return (
    <div
      className={
        show
          ? "w-full bg-black bg-opacity-20 fixed z-30 inset-0"
          : "hidden w-full bg-black bg-opacity-20 fixed z-30 inset-0"
      }
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        id="staticModal"
        data-modal-backdrop="static"
        tabindex="-1"
        aria-hidden="true"
        className="max-w-2xl mt-20 overflow-y-auto overflow-x-hidden fixed z-50 p-4 w-full md:inset-0 h-modal mx-auto md:h-full"
      >
        <div className="relative w-full max-w-2xl h-full md:h-auto">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow">
            {/* Modal header */}
            <div className="flex items-start p-4 rounded-t border-b">
              <div className="grow">
                <p className="font-bold text-lg">{`RQ-${request?.requestId}`}</p>
              </div>
              <button
                onClick={() => toggle()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-red-500 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                data-modal-toggle="staticModal"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="px-6 py-4">
              <div className="md:grid grid-cols-2 gap-x-5 gap-y-2">
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">Client Name: </p>
                  <p className="font-medium">{request?.clientId.fullName}</p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">Address: </p>
                  <p className="font-medium">{request?.address}</p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">State: </p>
                  <p className="font-medium">{request?.state}</p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">City: </p>
                  <p className="font-medium">{request?.city}</p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">Cost: </p>
                  <p className="font-medium">{request?.amount}</p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">No of Bags: </p>
                  <p className="font-medium">{request?.numberOfBags}</p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">Rating: </p>
                  <p className="font-medium">
                    {request?.rating?.rating ?? "Not Rated"}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">
                    Date of Pick Up:{" "}
                  </p>
                  <p className="font-medium">
                    {moment(new Date(request?.dateOfPickup)).format(
                      "MMMM Do YYYY"
                    )}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">
                    Assigned Staff:{" "}
                  </p>
                  <p className="font-medium">
                    {request?.staffId?.fullName ?? "Not Assigned"}
                  </p>
                </div>
              </div>
              <div>
                {!assignToStaff && !request?.staffId ? (
                  <Button
                    onClick={() => setAssignToStaff(true)}
                    disabled={false}
                    isLoading={false}
                    label="Assign to Staff"
                  />
                ) : assignToStaff ? (
                  <div>
                    <SelectInput
                      id="staff"
                      label="Select Staff"
                      value={selectedStaff?.fullName}
                      error={selectedStaffError}
                      errorMessage="Please select staff"
                      options={staff.map((item) => item.fullName)}
                      placeholder="Choose a Staff"
                      onChange={(e) => {
                        const data = staff.find(
                          (item) => item.fullName === e.target.value
                        );
                        setSelectedStaff(data);
                        setSelectedStaffError(false);
                      }}
                    />
                    <div className="grid grid-cols-2 gap-5">
                      <DangerButton
                        onClick={() => setAssignToStaff(false)}
                        disabled={false}
                        isLoading={false}
                        label="Cancel"
                      />
                      <Button
                        onClick={assignRequestToStaff}
                        disabled={false}
                        isLoading={loading}
                        label="Assign"
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <section className="overflow-hidden text-gray-700 ">
                <div className="container px-5 py-2 my-5">
                  <div className="grid grid-cols-3 -m-1 md:-m-2">
                    {request?.imageUrls.map((link, index) => (
                      <div className="w-full p-1 md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg"
                          src={link}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
