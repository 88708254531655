import React, { useState } from "react";
import AppAPI from "../../apis/appAPI";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/logo.png";
import Button from "../../components/Button";
import FormInput from "../../components/FormInput";
import { Link, useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const validateInput = (e) => {
    e.preventDefault();
    if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setEmailError(true);
      return;
    }

    sendResetEmail();
  };

  const sendResetEmail = async () => {
    setLoading(true);
    let data = { email };

    try {
      let response = await AppAPI.forgotPassword(data);
      Toast.success(response.message);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Forgot Password - Trash4Me</title>
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <nav className=" px-5 md:px-10 pt-2 border-b-2">
        <div className="container flex flex-wrap justify-between items-center mx-auto px-5 md:px-10 pb-4 ">
          <a href="/" className="flex items-center">
            <img src={logo} alt="" width={150} />
          </a>
        </div>
      </nav>
      <div className="container justify-center mx-auto py-10 w-full md:w-6/12 px-5">
        <p className="text-6xl text-center">Forgot Password?</p>
        <p className="text-gray-500 text-lg font-medium text-center my-4">
          No worries, we'll send you reset instructions
        </p>
        <form>
          <FormInput
            id="email"
            label="Email"
            placeholder="abc@xyz.com"
            required={true}
            type="email"
            value={email}
            error={emailError}
            errorMessage="Please enter a valid email address"
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false);
            }}
          />
          <p className="mt-2 text-gray-500 font-medium">
            We'll send reset instruction to this email address
          </p>
          <Button
            onClick={validateInput}
            disabled={false}
            isLoading={loading}
            label="Reset Password"
          />
        </form>
        <Link to="/login" className="text-medium">
          Remembered Password?{" "}
          <span className="font-bold text-lime-700">Login</span>
        </Link>
      </div>
    </div>
  );
}
