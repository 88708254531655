import React, { useState, useEffect } from "react";
import NotificationItem from "./NotificationItem";
import { useNavigate } from "react-router-dom";
import { Spinner } from "loading-animations-react";
import Toast from "../utils/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminAPI from "../apis/adminAPI";
import StaffAPI from "../apis/staffAPI";
import { useSelector } from "react-redux";

function NotificationSideBar({ show, toggle }) {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const [notifications, setNotifications] = useState([]);

  const getNotifications = async () => {
    setLoading(true);

    try {
      let response;
      if (user.role === "admin") {
        response = await AdminAPI.getNotifications();
      } else {
        response = await StaffAPI.getNotifications();
      }
      setNotifications(response.data);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div
      className={
        show
          ? "w-full bg-black bg-opacity-20 z-30 inset-0 fixed"
          : "hidden w-full bg-black bg-opacity-20 z-30 inset-0 fixed"
      }
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="fixed right-0 top-0 w-full md:w-96 h-screen p-4 overflow-y-auto bg-white">
        <h5 className="text-base font-semibold text-gray-500 uppercase">
          Notifications
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center"
        >
          <svg
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <div className="py-4 overflow-y-auto">
          <ul className="space-y-2">
            {loading && <Spinner className="w-8 h-8 m-5 " text="" />}
            {notifications.length &&
              notifications.map((notification, index) => (
                <NotificationItem
                  key={notification._id}
                  notification={notification}
                />
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NotificationSideBar;
