import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo2.png";
import phone1 from "../../assets/images/phone1.png";
import phone2 from "../../assets/images/phone2.png";
import phone3 from "../../assets/images/phone3.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import GooglePlayIcon from "../../assets/images/android.png";
import IOSIcon from "../../assets/images/ios.png";
import partner1Image from "../../assets/images/P1.png";
import partner2Image from "../../assets/images/P2.png";
import partner3Image from "../../assets/images/P3.jpg";
import partner4Image from "../../assets/images/P4.jpg";
import partner5Image from "../../assets/images/P5.jpg";
import partner6Image from "../../assets/images/P6.jpg";
import FormInput from "../../components/FormInput";
import TextArea from "../../components/TextArea";
import Button from "../../components/Button";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import AppAPI from "../../apis/appAPI";

function Home() {
  const aboutRef = useRef();
  const featuresRef = useRef();
  const testimoniesRef = useRef();
  const howItWorksRef = useRef();
  const contactUsRef = useRef();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);

  function handleClick(e, ref) {
    e.preventDefault();
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  const sendMessage = async () => {
    if (fullName.trim().length === 0) {
      setFullNameError(true);
      return;
    }

    if (email.trim().length === 0) {
      setEmailError(true);
      return;
    }

    if (message.trim().length === 0) {
      setMessageError(true);
      return;
    }

    setContactLoading(true);

    let data = { email, name: fullName, message };

    try {
      await AppAPI.contactUs(data);

      Toast.success("message sent successfully");
    } catch (err) {
      Toast.error(err.response.data.message);
    } finally {
      setContactLoading(false);
    }
  };

  return (
    <div className="bg-white w-full">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trash4Me</title>
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Top Section */}
      <div
        style={{ backgroundImage: "url(/images/background.png)" }}
        className="w-full bg-no-repeat bg-cover border-b-2"
      >
        <div className="max-w-7xl flex flex-wrap justify-between items-center mx-auto px-5 md:px-10">
          <Link to="/">
            <img src={logo} alt="" width={150} />
          </Link>
          <button
            type="button"
            onClick={() => {
              document.querySelector("#mobile-menu").classList.toggle("hidden");
            }}
            className="inline-flex p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          >
            <span className="sr-only">Open main menu</span>

            <svg
              width="32"
              height="28"
              viewBox="0 0 32 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="7.63635"
                y1="8.68188"
                x2="23.7576"
                y2="8.68188"
                stroke="#A6A6A6"
                stroke-width="3"
              />
              <line
                x1="7.63635"
                y1="8.68188"
                x2="23.7576"
                y2="8.68188"
                stroke="#A6A6A6"
                stroke-width="3"
              />
              <line
                x1="7.63635"
                y1="8.68188"
                x2="23.7576"
                y2="8.68188"
                stroke="#A6A6A6"
                stroke-width="3"
              />
              <line
                x1="7.63635"
                y1="13.7727"
                x2="23.7576"
                y2="13.7727"
                stroke="#A6A6A6"
                stroke-width="3"
              />
              <line
                x1="7.63635"
                y1="13.7727"
                x2="23.7576"
                y2="13.7727"
                stroke="#A6A6A6"
                stroke-width="3"
              />
              <line
                x1="7.63635"
                y1="13.7727"
                x2="23.7576"
                y2="13.7727"
                stroke="#A6A6A6"
                stroke-width="3"
              />
              <line
                x1="7.63635"
                y1="19.7122"
                x2="23.7576"
                y2="19.7122"
                stroke="#A6A6A6"
                stroke-width="3"
              />
              <line
                x1="7.63635"
                y1="19.7122"
                x2="23.7576"
                y2="19.7122"
                stroke="#A6A6A6"
                stroke-width="3"
              />
              <line
                x1="7.63635"
                y1="19.7122"
                x2="23.7576"
                y2="19.7122"
                stroke="#A6A6A6"
                stroke-width="3"
              />
              <rect
                x="0.5"
                y="0.5"
                width="30.3939"
                height="27"
                rx="9.5"
                stroke="#A6A6A6"
              />
            </svg>
          </button>
          <div className="hidden w-full md:block md:w-auto" id="mobile-menu">
            <ul className="flex flex-col p-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-lg md:font-medium text-lime">
              <li>
                <button
                  onClick={(e) => handleClick(e, aboutRef)}
                  className="block py-2 pr-4 pl-3 hover:text-lime-500 md:p-0 bg-transparent "
                >
                  About
                </button>
              </li>
              <li>
                <button
                  onClick={(e) => handleClick(e, featuresRef)}
                  className="block py-2 pr-4 pl-3 hover:text-lime-500 md:p-0 bg-transparent "
                >
                  Features
                </button>
              </li>
              <li>
                <button
                  onClick={(e) => handleClick(e, testimoniesRef)}
                  className="block py-2 pr-4 pl-3 hover:text-lime-500 md:p-0 bg-transparent "
                >
                  Testimonies
                </button>
              </li>
              <li>
                <button
                  onClick={(e) => handleClick(e, howItWorksRef)}
                  className="block py-2 pr-4 pl-3 hover:text-lime-500 md:p-0 bg-transparent "
                >
                  How it works
                </button>
              </li>
              <li>
                <button
                  onClick={(e) => handleClick(e, contactUsRef)}
                  className="block py-2 pr-4 pl-3 hover:text-lime-500 md:p-0 bg-transparent"
                >
                  Contact Us
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="max-w-7xl mx-8 md:mx-auto p-5 md:p-20 md:grid grid-cols-2 items-center justify-center">
          <div>
            <p className="md:-mt-32 text-4xl md:text-6xl font-semibold text-left">
              Providing
              <p>
                <span className="text-lime-500">Quality</span> Waste
              </p>
              <p> Disposal Service</p>
            </p>
            <div className="mt-10 flex space-x-10 items-center w-full">
              <a
                href="https://play.google.com/store/apps/details?id=com.trash4me.trash4me"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={GooglePlayIcon}
                  alt="Google Play Store Icon"
                  className="w-32 md:w-48 "
                />
              </a>
              <a
                href="https://apps.apple.com/app/id6449929728"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={IOSIcon}
                  alt="Apple Store Icon"
                  className="w-32 md:w-48"
                />
              </a>
            </div>
          </div>
          <div className="mt-14 w-56 mx-auto md:mx-0 md:w-max flex justify-center">
            <img src={phone1} alt="phone1" />
          </div>
        </div>
      </div>

      {/* Brand Section */}
      <div className="w-full mx-auto px-5 mt-10 md:px-10 border-b-2">
        <p className="font-semibold text-3xl text-center lg:text-5xl">
          Partners
        </p>
        <div className="max-w-3xl mx-auto my-10 gap-x-4 justify-center items-center">
          <Swiper
            spaceBetween={30}
            slidesPerView={3}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={partner1Image} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={partner2Image} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={partner3Image} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={partner4Image} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={partner5Image} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={partner6Image} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      {/* Features Section */}
      <div ref={featuresRef} className="max-w-7xl mx-auto px-5 md:px-10">
        <div className="py-5 md:w-2/5 mx-auto">
          <p className="font-semibold text-3xl text-center lg:text-5xl">
            Tailor-made features
          </p>
          <p className="text-gray-700 text-center">
            Seamless technology for end-to-end procurement and disposal of waste
            from the comfort of your phone
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          <div className="flex flex-col justify-center items-center p-5">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_36_1898)">
                <path
                  d="M24 13.3333H32L28 8L24 13.3333ZM24 18.6667H32L28 24L24 18.6667Z"
                  fill="#A6A6A6"
                />
                <path
                  d="M1.33333 0H9.33333V32H1.33333C0.979711 32 0.640572 31.8595 0.390523 31.6095C0.140475 31.3594 0 31.0203 0 30.6667V1.33333C0 0.979711 0.140475 0.640572 0.390523 0.390523C0.640572 0.140475 0.979711 0 1.33333 0Z"
                  fill="#58AF29"
                />
                <path
                  d="M12 0H20C20.3536 0 20.6928 0.140475 20.9428 0.390523C21.1929 0.640572 21.3333 0.979711 21.3333 1.33333V30.6667C21.3333 31.0203 21.1929 31.3594 20.9428 31.6095C20.6928 31.8595 20.3536 32 20 32H12V0Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_36_1898">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p className="font-medium text-2xl text-center">
              Seamless workflow
            </p>
            <p className="text-gray-700 text-center">
              We combined the latest trends of technology to provide hassle free
              way to dispose waste
            </p>
          </div>
          <div className="flex flex-col justify-center items-center p-5">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.33331 1.33337H30.6666L26.6666 9.33337H1.33331L5.33331 1.33337Z"
                fill="#58AF29"
              />
              <path
                d="M5.33331 12H30.6666L26.6666 20H1.33331L5.33331 12Z"
                fill="black"
              />
              <path
                d="M5.33331 22.6667H30.6666L26.6666 30.6667H1.33331L5.33331 22.6667Z"
                fill="#A6A6A6"
              />
            </svg>
            <p className="font-medium text-2xl text-center">
              Realtime Notification
            </p>
            <p className="text-gray-700 text-center">
              Get instant alert on arrival of our pickup agents at your location
              to receive garbage
            </p>
          </div>
          <div className="flex flex-col justify-center items-center p-5">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.6667 8.00004V2.66671C30.6667 2.31309 30.5262 1.97395 30.2762 1.7239C30.0261 1.47385 29.687 1.33337 29.3333 1.33337H2.66668C2.31305 1.33337 1.97392 1.47385 1.72387 1.7239C1.47382 1.97395 1.33334 2.31309 1.33334 2.66671V8.00004H30.6667Z"
                fill="black"
              />
              <path
                d="M1.33334 10.6667V29.3334C1.33334 29.687 1.47382 30.0262 1.72387 30.2762C1.97392 30.5263 2.31305 30.6667 2.66668 30.6667H9.33334V10.6667H1.33334Z"
                fill="#A6A6A6"
              />
              <path
                d="M12 30.6667H29.3333C29.687 30.6667 30.0261 30.5263 30.2761 30.2762C30.5262 30.0262 30.6667 29.687 30.6667 29.3334V10.6667H12V30.6667Z"
                fill="#58AF29"
              />
            </svg>
            <p className="font-medium text-2xl text-center">User friendly</p>
            <p className="text-gray-700 text-center">
              Easy to use app for our customers to have a unique experience with
              our app
            </p>
          </div>
          <div className="flex flex-col justify-center items-center p-5">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28 32H16C15.6463 32 15.3072 31.8595 15.0571 31.6095C14.8071 31.3594 14.6666 31.0203 14.6666 30.6667V28C14.6666 27.6464 14.8071 27.3073 15.0571 27.0572C15.3072 26.8072 15.6463 26.6667 16 26.6667H28C28.3536 26.6667 28.6927 26.8072 28.9428 27.0572C29.1928 27.3073 29.3333 27.6464 29.3333 28V30.6667C29.3333 31.0203 29.1928 31.3594 28.9428 31.6095C28.6927 31.8595 28.3536 32 28 32Z"
                fill="#A6A6A6"
              />
              <path
                d="M28 0H3.99996C3.64634 0 3.3072 0.140475 3.05715 0.390523C2.8071 0.640572 2.66663 0.979711 2.66663 1.33333V4C2.66663 4.35362 2.8071 4.69276 3.05715 4.94281C3.3072 5.19286 3.64634 5.33333 3.99996 5.33333H28C28.3536 5.33333 28.6927 5.19286 28.9428 4.94281C29.1928 4.69276 29.3333 4.35362 29.3333 4V1.33333C29.3333 0.979711 29.1928 0.640572 28.9428 0.390523C28.6927 0.140475 28.3536 0 28 0Z"
                fill="black"
              />
              <path
                d="M28 8H3.99996C3.64634 8 3.3072 8.14047 3.05715 8.39052C2.8071 8.64057 2.66663 8.97971 2.66663 9.33333V22.6667C2.66663 23.0203 2.8071 23.3594 3.05715 23.6095C3.3072 23.8595 3.64634 24 3.99996 24H28C28.3536 24 28.6927 23.8595 28.9428 23.6095C29.1928 23.3594 29.3333 23.0203 29.3333 22.6667V9.33333C29.3333 8.97971 29.1928 8.64057 28.9428 8.39052C28.6927 8.14047 28.3536 8 28 8Z"
                fill="#58AF29"
              />
            </svg>
            <p className="font-medium text-2xl text-center">Easy Geolocation</p>
            <p className="text-gray-700 text-center">
              Allows users to instantly set location of garbage pickup and add
              favourite locations
            </p>
          </div>
          <div className="flex flex-col justify-center items-center p-5">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.66665 1.33331H2.66665C2.31302 1.33331 1.97388 1.47379 1.72384 1.72384C1.47379 1.97388 1.33331 2.31302 1.33331 2.66665V29.3333C1.33331 29.6869 1.47379 30.0261 1.72384 30.2761C1.97388 30.5262 2.31302 30.6666 2.66665 30.6666H6.66665V1.33331Z"
                fill="#A6A6A6"
              />
              <path
                d="M9.33331 30.6666H22.6666V1.33331H9.33331V30.6666ZM16 25.3333C15.6464 25.3333 15.3072 25.1928 15.0572 24.9428C14.8071 24.6927 14.6666 24.3536 14.6666 24C14.6666 23.6464 14.8071 23.3072 15.0572 23.0572C15.3072 22.8071 15.6464 22.6666 16 22.6666C16.3536 22.6666 16.6927 22.8071 16.9428 23.0572C17.1928 23.3072 17.3333 23.6464 17.3333 24C17.3333 24.3536 17.1928 24.6927 16.9428 24.9428C16.6927 25.1928 16.3536 25.3333 16 25.3333ZM16 6.66665C16.3536 6.66665 16.6927 6.80712 16.9428 7.05717C17.1928 7.30722 17.3333 7.64636 17.3333 7.99998C17.3333 8.3536 17.1928 8.69274 16.9428 8.94279C16.6927 9.19284 16.3536 9.33331 16 9.33331C15.6464 9.33331 15.3072 9.19284 15.0572 8.94279C14.8071 8.69274 14.6666 8.3536 14.6666 7.99998C14.6666 7.64636 14.8071 7.30722 15.0572 7.05717C15.3072 6.80712 15.6464 6.66665 16 6.66665ZM16 14.6666C16.3536 14.6666 16.6927 14.8071 16.9428 15.0572C17.1928 15.3072 17.3333 15.6464 17.3333 16C17.3333 16.3536 17.1928 16.6927 16.9428 16.9428C16.6927 17.1928 16.3536 17.3333 16 17.3333C15.6464 17.3333 15.3072 17.1928 15.0572 16.9428C14.8071 16.6927 14.6666 16.3536 14.6666 16C14.6666 15.6464 14.8071 15.3072 15.0572 15.0572C15.3072 14.8071 15.6464 14.6666 16 14.6666Z"
                fill="#58AF29"
              />
              <path
                d="M29.3333 1.33331H25.3333V30.6666H29.3333C29.6869 30.6666 30.0261 30.5262 30.2761 30.2761C30.5262 30.0261 30.6666 29.6869 30.6666 29.3333V2.66665C30.6666 2.31302 30.5262 1.97388 30.2761 1.72384C30.0261 1.47379 29.6869 1.33331 29.3333 1.33331Z"
                fill="black"
              />
            </svg>
            <p className="font-medium text-2xl text-center">Easy disposal</p>
            <p className="text-gray-700 text-center">
              Save time disposing Trash from the comfort of your home with your
              phone
            </p>
          </div>
          <div className="flex flex-col justify-center items-center p-5">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_36_1942)">
                <path
                  d="M15.2078 0.175992L0.781159 6.91599C0.683598 6.96073 0.60095 7.03256 0.543053 7.12294C0.485156 7.21331 0.45445 7.31842 0.45459 7.42575C0.454731 7.53308 0.485711 7.63811 0.543844 7.72833C0.601977 7.81856 0.684814 7.89017 0.782492 7.93466L15.3225 14.6253C15.7518 14.8227 16.2478 14.8227 16.6772 14.6253L31.2172 7.93466C31.3149 7.8903 31.3978 7.81879 31.4561 7.72865C31.5143 7.6385 31.5454 7.53351 31.5457 7.42618C31.546 7.31885 31.5154 7.2137 31.4577 7.12325C31.3999 7.0328 31.3173 6.96086 31.2198 6.91599L16.7918 0.175992C16.5439 0.0601174 16.2735 6.10352e-05 15.9998 6.10352e-05C15.7261 6.10352e-05 15.4558 0.0601174 15.2078 0.175992Z"
                  fill="#58AF29"
                />
                <path
                  opacity="0.64"
                  d="M31.22 15.568L28.24 14.176C28.0266 14.0763 27.794 14.0243 27.5584 14.0236C27.3229 14.0229 27.09 14.0735 26.876 14.172L16.6773 18.8653C16.4649 18.963 16.2338 19.0136 16 19.0136C15.7662 19.0136 15.5351 18.963 15.3227 18.8653L5.12267 14.172C4.90885 14.0737 4.67621 14.0232 4.4409 14.0239C4.20559 14.0246 3.97324 14.0765 3.76001 14.176L0.780006 15.568C0.682503 15.6128 0.599949 15.6848 0.54217 15.7752C0.48439 15.8657 0.453823 15.9708 0.454103 16.0782C0.454384 16.1855 0.485502 16.2905 0.543754 16.3806C0.602006 16.4708 0.684937 16.5423 0.782674 16.5866L15.3227 23.276C15.535 23.3742 15.7661 23.4251 16 23.4251C16.2339 23.4251 16.465 23.3742 16.6773 23.276L31.2173 16.5866C31.3151 16.5423 31.398 16.4708 31.4563 16.3806C31.5145 16.2905 31.5456 16.1855 31.5459 16.0782C31.5462 15.9708 31.5156 15.8657 31.4578 15.7752C31.4001 15.6848 31.3175 15.6128 31.22 15.568Z"
                  fill="#A6A6A6"
                />
                <path
                  d="M31.22 24.144L28.24 22.752C28.0266 22.6522 27.794 22.6002 27.5585 22.5995C27.3229 22.5989 27.09 22.6495 26.876 22.748L16.6774 27.44C16.4649 27.5376 16.2338 27.5882 16 27.5882C15.7662 27.5882 15.5351 27.5376 15.3227 27.44L5.12269 22.7466C4.90887 22.6484 4.67623 22.5978 4.44091 22.5985C4.2056 22.5992 3.97326 22.6511 3.76002 22.7506L0.780019 24.144C0.682891 24.189 0.600708 24.2609 0.543205 24.3512C0.485701 24.4415 0.455288 24.5464 0.455568 24.6535C0.455849 24.7605 0.486813 24.8653 0.544789 24.9553C0.602765 25.0453 0.685324 25.1168 0.782686 25.1613L15.3227 31.852C15.752 32.0493 16.248 32.0493 16.6774 31.852L31.2174 25.1613C31.3147 25.1168 31.3973 25.0453 31.4553 24.9553C31.5132 24.8653 31.5442 24.7605 31.5445 24.6535C31.5448 24.5464 31.5143 24.4415 31.4568 24.3512C31.3993 24.2609 31.3172 24.189 31.22 24.144Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_36_1942">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p className="font-medium text-2xl text-center">Trash4me Agent</p>
            <p className="text-gray-700 text-center">
              Become a registered agent and start earning working with us
            </p>
          </div>
        </div>
      </div>

      {/* Testimonias Section */}
      <div ref={testimoniesRef} className="w-full px-5 md:px-10 bg-gray-100">
        <div className="mx-auto max-w-7xl md:grid grid-cols-2 gap-5 p-5 md:p-20">
          <div className="relative">
            <svg
              className="w-10 h-12 md:w-32 md:h-36"
              viewBox="0 0 143 120"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.3" clip-path="url(#clip0_43_949)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M142.373 18.7419C121.049 29.1541 110.387 41.3882 110.387 55.4447C119.476 56.4859 126.992 60.1735 132.934 66.5076C138.877 72.8417 141.849 80.1735 141.849 88.5033C141.849 97.3536 138.965 104.816 133.197 110.889C127.428 116.963 120.175 120 111.435 120C101.647 120 93.1701 116.052 86.0037 108.156C78.8374 100.26 75.2542 90.6725 75.2542 79.3926C75.2542 45.553 94.306 19.089 132.41 0L142.373 18.7419ZM67.1186 18.7419C45.6196 29.1541 34.8702 41.3882 34.8702 55.4447C44.134 56.4859 51.7373 60.1735 57.6801 66.5076C63.6229 72.8417 66.5943 80.1735 66.5943 88.5033C66.5943 97.3536 63.6666 104.816 57.8112 110.889C51.9557 116.963 44.6584 120 35.919 120C26.1308 120 17.6973 116.052 10.6184 108.156C3.53942 100.26 0 90.6725 0 79.3926C0 45.553 18.9643 19.089 56.8935 0L67.1186 18.7419Z"
                  fill="black"
                  fill-opacity="0.37"
                />
              </g>
              <defs>
                <clipPath id="clip0_43_949">
                  <rect width="143" height="120" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="absolute z-10 top-5 left-7 md:top-16 md:left-24">
              <p className="text-2xl md:text-6xl font-semibold">
                Real Stories from Customers
              </p>
              <p className="mt-3">Get inspired by these stories.</p>
            </div>
            <div className="mt-10 md:ml-48 md:mt-20 md:w-4/6 bg-white rounded-lg p-5 md:p-10">
              <img
                class="w-10 h-10 rounded-full ml-5"
                src="https://picsum.photos/200"
                alt="Rounded avatar"
              />
              <div className="grid grid-cols-10 mt-3">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.3" clip-path="url(#clip0_43_974)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.9298 2.18655C13.5439 3.40131 12.351 4.82863 12.351 6.46855C13.3679 6.59002 14.2088 7.02024 14.8738 7.75922C15.5387 8.4982 15.8712 9.35357 15.8712 10.3254C15.8712 11.3579 15.5485 12.2285 14.9031 12.9371C14.2577 13.6457 13.4461 14 12.4683 14C11.3731 14 10.4246 13.5394 9.62279 12.6182C8.82096 11.697 8.42005 10.5785 8.42005 9.26247C8.42005 5.31451 10.5517 2.22705 14.8151 0L15.9298 2.18655ZM7.50978 2.18655C5.10429 3.40131 3.90156 4.82863 3.90156 6.46855C4.93808 6.59002 5.78878 7.02024 6.45372 7.75922C7.11865 8.4982 7.45111 9.35357 7.45111 10.3254C7.45111 11.3579 7.12354 12.2285 6.46838 12.9371C5.81323 13.6457 4.99675 14 4.0189 14C2.92372 14 1.98012 13.5394 1.18807 12.6182C0.39602 11.697 0 10.5785 0 9.26247C0 5.31451 2.12188 2.22705 6.36571 0L7.50978 2.18655Z"
                      fill="#2EC5CE"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_43_974">
                      <rect width="16" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="col-span-9">
                  <p>
                    A lifesaver in an emergency! I had an unexpected overflow of
                    trash and urgently needed it removed. This trash disposal
                    company came to the rescue. They quickly dispatched a team
                    that efficiently cleared the debris, saving me from a major
                    headache. Their prompt response and swift action deserve
                    commendation. I'm incredibly grateful for their service.
                  </p>
                  <p className="mt-5 font-bold">Divine Olajide</p>
                  <p>Customer Umuahia Abia State </p>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="mt-5 md:mt-28 md:w-4/5 bg-white rounded-lg p-5 md:p-10">
              <img
                class="w-10 h-10 rounded-full ml-8"
                src="https://picsum.photos/200"
                alt="Rounded avatar"
              />
              <div className="grid grid-cols-10 mt-3">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.3" clip-path="url(#clip0_43_974)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.9298 2.18655C13.5439 3.40131 12.351 4.82863 12.351 6.46855C13.3679 6.59002 14.2088 7.02024 14.8738 7.75922C15.5387 8.4982 15.8712 9.35357 15.8712 10.3254C15.8712 11.3579 15.5485 12.2285 14.9031 12.9371C14.2577 13.6457 13.4461 14 12.4683 14C11.3731 14 10.4246 13.5394 9.62279 12.6182C8.82096 11.697 8.42005 10.5785 8.42005 9.26247C8.42005 5.31451 10.5517 2.22705 14.8151 0L15.9298 2.18655ZM7.50978 2.18655C5.10429 3.40131 3.90156 4.82863 3.90156 6.46855C4.93808 6.59002 5.78878 7.02024 6.45372 7.75922C7.11865 8.4982 7.45111 9.35357 7.45111 10.3254C7.45111 11.3579 7.12354 12.2285 6.46838 12.9371C5.81323 13.6457 4.99675 14 4.0189 14C2.92372 14 1.98012 13.5394 1.18807 12.6182C0.39602 11.697 0 10.5785 0 9.26247C0 5.31451 2.12188 2.22705 6.36571 0L7.50978 2.18655Z"
                      fill="#2EC5CE"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_43_974">
                      <rect width="16" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="col-span-9">
                  <p>
                    Impressed by their eco-friendly approach! This trash
                    disposal company truly cares about the environment. They
                    actively promote recycling and proper waste management.
                    Their dedication to sustainability sets them apart from
                    other companies. I appreciate their commitment to reducing
                    the impact on our planet. A responsible choice for trash
                    disposal.
                  </p>
                  <p className="mt-5 font-bold">Chike Okoronkwo</p>
                  <p>Customer Umuahia Abia State </p>
                </div>
              </div>
            </div>
            <div className="mt-5 md:w-4/5 bg-white rounded-lg p-5 md:p-10">
              <img
                class="w-10 h-10 rounded-full ml-8"
                src="https://picsum.photos/200"
                alt="Rounded avatar"
              />
              <div className="grid grid-cols-10 mt-3">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.3" clip-path="url(#clip0_43_974)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.9298 2.18655C13.5439 3.40131 12.351 4.82863 12.351 6.46855C13.3679 6.59002 14.2088 7.02024 14.8738 7.75922C15.5387 8.4982 15.8712 9.35357 15.8712 10.3254C15.8712 11.3579 15.5485 12.2285 14.9031 12.9371C14.2577 13.6457 13.4461 14 12.4683 14C11.3731 14 10.4246 13.5394 9.62279 12.6182C8.82096 11.697 8.42005 10.5785 8.42005 9.26247C8.42005 5.31451 10.5517 2.22705 14.8151 0L15.9298 2.18655ZM7.50978 2.18655C5.10429 3.40131 3.90156 4.82863 3.90156 6.46855C4.93808 6.59002 5.78878 7.02024 6.45372 7.75922C7.11865 8.4982 7.45111 9.35357 7.45111 10.3254C7.45111 11.3579 7.12354 12.2285 6.46838 12.9371C5.81323 13.6457 4.99675 14 4.0189 14C2.92372 14 1.98012 13.5394 1.18807 12.6182C0.39602 11.697 0 10.5785 0 9.26247C0 5.31451 2.12188 2.22705 6.36571 0L7.50978 2.18655Z"
                      fill="#2EC5CE"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_43_974">
                      <rect width="16" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="col-span-9">
                  <p>
                    I recently hired this trash disposal company for a major
                    cleanup project, and I'm incredibly satisfied with their
                    service. They handled the entire process smoothly, from the
                    initial consultation to the final cleanup. Their team was
                    skilled, efficient, and respectful of my property. They made
                    what could have been a daunting task so much easier.
                  </p>
                  <p className="mt-5 font-bold">Emmanuel Uche</p>
                  <p>Customer Umuahia Abia State </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* How it works section */}
      <div ref={howItWorksRef} className="w-full px-5 md:px-10">
        <div className="mx-auto max-w-7xl md:grid grid-cols-2 gap-5 p-5 md:p-16">
          <div>
            <p className="text-5xl font-semibold">How it works</p>
            <p className="text-xl">
              Seamless technology for end-to-end procurement and disposal of
              waste powered by T2T environmentals
            </p>
          </div>
          <div className="mt-5 md:mt-0 md:grid grid-cols-2 gap-5">
            <div className="flex space-x-5 justify-center items-center">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_44_1014)">
                  <path
                    d="M32 21.3333H40L36 16L32 21.3333ZM32 26.6667H40L36 32L32 26.6667Z"
                    fill="black"
                  />
                  <path
                    d="M9.33333 8H17.3333V40H9.33333C8.97971 40 8.64057 39.8595 8.39052 39.6095C8.14047 39.3594 8 39.0203 8 38.6667V9.33333C8 8.97971 8.14047 8.64057 8.39052 8.39052C8.64057 8.14047 8.97971 8 9.33333 8Z"
                    fill="#58AF29"
                  />
                  <path
                    d="M20 8H28C28.3536 8 28.6928 8.14047 28.9428 8.39052C29.1929 8.64057 29.3333 8.97971 29.3333 9.33333V38.6667C29.3333 39.0203 29.1929 39.3594 28.9428 39.6095C28.6928 39.8595 28.3536 40 28 40H20V8Z"
                    fill="#A6A6A6"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_44_1014">
                    <rect
                      width="32"
                      height="32"
                      fill="white"
                      transform="translate(8 8)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p className="text-xl">Request for trash pickup with the App</p>
            </div>
            <div className="flex space-x-5 justify-center items-center">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 40H24C23.6464 40 23.3072 39.8595 23.0572 39.6095C22.8071 39.3594 22.6667 39.0203 22.6667 38.6667V36C22.6667 35.6464 22.8071 35.3072 23.0572 35.0572C23.3072 34.8071 23.6464 34.6667 24 34.6667H36C36.3536 34.6667 36.6928 34.8071 36.9428 35.0572C37.1928 35.3072 37.3333 35.6464 37.3333 36V38.6667C37.3333 39.0203 37.1928 39.3594 36.9428 39.6095C36.6928 39.8595 36.3536 40 36 40Z"
                  fill="black"
                />
                <path
                  d="M36 8H12C11.6464 8 11.3072 8.14047 11.0572 8.39052C10.8071 8.64057 10.6667 8.97971 10.6667 9.33333V12C10.6667 12.3536 10.8071 12.6928 11.0572 12.9428C11.3072 13.1929 11.6464 13.3333 12 13.3333H36C36.3536 13.3333 36.6928 13.1929 36.9428 12.9428C37.1928 12.6928 37.3333 12.3536 37.3333 12V9.33333C37.3333 8.97971 37.1928 8.64057 36.9428 8.39052C36.6928 8.14047 36.3536 8 36 8Z"
                  fill="#A6A6A6"
                />
                <path
                  d="M36 16H12C11.6464 16 11.3072 16.1405 11.0572 16.3905C10.8071 16.6406 10.6667 16.9797 10.6667 17.3333V30.6667C10.6667 31.0203 10.8071 31.3594 11.0572 31.6095C11.3072 31.8595 11.6464 32 12 32H36C36.3536 32 36.6928 31.8595 36.9428 31.6095C37.1928 31.3594 37.3333 31.0203 37.3333 30.6667V17.3333C37.3333 16.9797 37.1928 16.6406 36.9428 16.3905C36.6928 16.1405 36.3536 16 36 16Z"
                  fill="#58AF29"
                />
              </svg>
              <p className="text-xl">Complete your order request form</p>
            </div>
            <div className="flex space-x-5 justify-center items-center">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.6666 9.33334H10.6666C10.313 9.33334 9.97388 9.47382 9.72384 9.72387C9.47379 9.97392 9.33331 10.3131 9.33331 10.6667V37.3333C9.33331 37.687 9.47379 38.0261 9.72384 38.2762C9.97388 38.5262 10.313 38.6667 10.6666 38.6667H14.6666V9.33334Z"
                  fill="black"
                />
                <path
                  d="M17.3333 38.6667H30.6666V9.33334H17.3333V38.6667ZM24 33.3333C23.6464 33.3333 23.3072 33.1929 23.0572 32.9428C22.8071 32.6928 22.6666 32.3536 22.6666 32C22.6666 31.6464 22.8071 31.3072 23.0572 31.0572C23.3072 30.8072 23.6464 30.6667 24 30.6667C24.3536 30.6667 24.6927 30.8072 24.9428 31.0572C25.1928 31.3072 25.3333 31.6464 25.3333 32C25.3333 32.3536 25.1928 32.6928 24.9428 32.9428C24.6927 33.1929 24.3536 33.3333 24 33.3333ZM24 14.6667C24.3536 14.6667 24.6927 14.8072 24.9428 15.0572C25.1928 15.3072 25.3333 15.6464 25.3333 16C25.3333 16.3536 25.1928 16.6928 24.9428 16.9428C24.6927 17.1929 24.3536 17.3333 24 17.3333C23.6464 17.3333 23.3072 17.1929 23.0572 16.9428C22.8071 16.6928 22.6666 16.3536 22.6666 16C22.6666 15.6464 22.8071 15.3072 23.0572 15.0572C23.3072 14.8072 23.6464 14.6667 24 14.6667ZM24 22.6667C24.3536 22.6667 24.6927 22.8072 24.9428 23.0572C25.1928 23.3072 25.3333 23.6464 25.3333 24C25.3333 24.3536 25.1928 24.6928 24.9428 24.9428C24.6927 25.1929 24.3536 25.3333 24 25.3333C23.6464 25.3333 23.3072 25.1929 23.0572 24.9428C22.8071 24.6928 22.6666 24.3536 22.6666 24C22.6666 23.6464 22.8071 23.3072 23.0572 23.0572C23.3072 22.8072 23.6464 22.6667 24 22.6667Z"
                  fill="#58AF29"
                />
                <path
                  d="M37.3333 9.33334H33.3333V38.6667H37.3333C37.6869 38.6667 38.0261 38.5262 38.2761 38.2762C38.5262 38.0261 38.6666 37.687 38.6666 37.3333V10.6667C38.6666 10.3131 38.5262 9.97392 38.2761 9.72387C38.0261 9.47382 37.6869 9.33334 37.3333 9.33334Z"
                  fill="#A6A6A6"
                />
              </svg>
              <p className="text-xl">
                Await agents arrival at your door steps in mins
              </p>
            </div>
            <div className="flex space-x-5 justify-center items-center">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 21.3333H10.6667C10.3131 21.3333 9.97392 21.1929 9.72387 20.9428C9.47382 20.6928 9.33334 20.3536 9.33334 20V10.6667C9.33334 10.3131 9.47382 9.97392 9.72387 9.72387C9.97392 9.47382 10.3131 9.33334 10.6667 9.33334H20C20.3536 9.33334 20.6928 9.47382 20.9428 9.72387C21.1929 9.97392 21.3333 10.3131 21.3333 10.6667V20C21.3333 20.3536 21.1929 20.6928 20.9428 20.9428C20.6928 21.1929 20.3536 21.3333 20 21.3333Z"
                  fill="#58AF29"
                />
                <path
                  d="M20 38.6667H10.6667C10.3131 38.6667 9.97392 38.5262 9.72387 38.2762C9.47382 38.0261 9.33334 37.687 9.33334 37.3334V28C9.33334 27.6464 9.47382 27.3073 9.72387 27.0572C9.97392 26.8072 10.3131 26.6667 10.6667 26.6667H20C20.3536 26.6667 20.6928 26.8072 20.9428 27.0572C21.1929 27.3073 21.3333 27.6464 21.3333 28V37.3334C21.3333 37.687 21.1929 38.0261 20.9428 38.2762C20.6928 38.5262 20.3536 38.6667 20 38.6667Z"
                  fill="#58AF29"
                />
                <path
                  d="M25.3333 10.6667H38.6667V13.3334H25.3333V10.6667Z"
                  fill="#A6A6A6"
                />
                <path
                  d="M38.6667 28H25.3333V30.6667H38.6667V28Z"
                  fill="#A6A6A6"
                />
                <path
                  d="M38.6667 34.6667H25.3333V37.3334H38.6667V34.6667Z"
                  fill="black"
                />
                <path
                  d="M38.6667 17.3333H25.3333V20H38.6667V17.3333Z"
                  fill="black"
                />
              </svg>
              <p className="text-xl">Invite other and earn booking discounts</p>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Us Section */}
      <div ref={contactUsRef} className="w-full px-5 md:px-10">
        <div className="mx-auto max-w-7xl md:grid grid-cols-2 gap-5 p-5 md:p-16">
          <div className="hidden md:flex justify-center">
            <img src={phone2} alt="phone2" />
          </div>
          <div className="md:py-20">
            <p className="text-3xl font-bold">Leave a Message</p>
            <p className="text-lg mt-3">
              Send us a quick message if you have any doubts
            </p>
            <form className="">
              <FormInput
                id="full_name"
                label="Full Name"
                type="text"
                error={fullNameError}
                errorMessage="Please Enter your full name"
                value={fullName}
                placeholder="full name"
                required={true}
                onChange={(e) => {
                  setFullName(e.target.value);
                  setFullNameError(false);
                }}
              />
              <FormInput
                id="email"
                label="Email Address"
                type="email"
                value={email}
                error={emailError}
                errorMessage="Please Enter your email address"
                placeholder="xyz@abc.com"
                required={true}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(false);
                }}
              />
              <TextArea
                id="message"
                label="Message"
                value={message}
                error={messageError}
                errorMessage="Please Enter the mmessage"
                placeholder="I want to say that"
                required={true}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setMessageError(false);
                }}
              />
              <Button
                onClick={sendMessage}
                isLoading={contactLoading}
                label="Send Message"
              />
            </form>
          </div>
        </div>
      </div>

      {/* About Section */}
      <div ref={aboutRef} className="w-full px-5 md:px-10 bg-gray-200">
        <div className="mx-auto max-w-7xl md:grid grid-cols-2 gap-5">
          <div className="md:hidden">
            <img src={phone3} alt="phone3" />
          </div>
          <div className="p-5 md:p-20">
            <p className="text-2xl md:text-5xl font-bold">
              Dispose your Trash from your mobile
            </p>
            <p className="text-lg mt-2">
              Download the app to manage your requests, keep track of the
              progress and complete orders without procrastinating. Stay on
              track and save you time & money!
            </p>
            <p className="text-lg mt-10">Get the App</p>
            <div className="mt-2 flex space-x-10">
              <a
                href="https://play.google.com/store/apps/details?id=com.trash4me.trash4me"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={GooglePlayIcon}
                  alt="Google Play Store Icon"
                  className="w-32  md:w-48 "
                />
              </a>
              <a
                href="https://apps.apple.com/app/id6449929728"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={IOSIcon}
                  alt="Apple Store Icon"
                  className="w-32  md:w-48"
                />
              </a>
            </div>
          </div>
          <div className="hidden md:block">
            <img src={phone3} alt="phone3" />
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <div className="w-full px-5 md:px-10 bg-black">
        <div className="mx-auto max-w-7xl text-white flex space-x-5 py-10">
          <div>
            <Link to="/">
              <img src={logo2} alt="" width={150} />
            </Link>
            <p className="mt-3">support@trash4me.com</p>
            <p className="mt-3">+234 (0) 806 442 3806</p>
            <p className="mt-3">© 2022Trash4me™. All Rights Reserved.</p>
            <div className="flex space-x-3">
              <Link to="/privacy">
                <p className="mt-3">Privacy Policy</p>
              </Link>
              <Link to="/terms">
                <p className="mt-3">Terms of service</p>
              </Link>
            </div>
          </div>
          <div className="grow"></div>
          <div className="flex flex-col space-y-3">
            <p>Get the App</p>
            <a
              href="https://play.google.com/store/apps/details?id=com.trash4me.trash4me"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={GooglePlayIcon}
                alt="Google Play Store Icon"
                className="w-32 md:w-48 border rounded-lg"
              />
            </a>
            <a
              href="https://apps.apple.com/app/id6449929728"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={IOSIcon}
                alt="Apple Store Icon"
                className="w-32 border rounded-lg md:w-48"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
