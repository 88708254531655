import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { Chart } from "react-google-charts";
import { Helmet } from "react-helmet";
import {
  HiHome,
  HiChatBubbleLeftEllipsis,
  HiEnvelope,
  HiUser,
  HiCreditCard,
  HiBanknotes,
  HiAdjustmentsHorizontal,
  HiArrowLeftOnRectangle,
  HiClipboardDocumentCheck,
  HiUserGroup,
} from "react-icons/hi2";
import { Spinner } from "loading-animations-react";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import RequestAPI from "../../apis/requestAPI";
import RequestModal from "../../components/modals/RequestModal";
import LogoutModal from "../../components/modals/LogoutModal";
import NotificationSideBar from "../../components/NotificationSideBar";

export const options = {
  title: "Pickups",
};

export default function Requests() {
  const [filterOption, setFilterOption] = useState("All");
  const [showFilters, setShowFilters] = useState(false);
  const filterOptions = ["All", "Completed", "Pending", "Failed"];
  const [showLogout, setShowLogout] = useState(false);
  let navigate = useNavigate();
  let [searchParams, _] = useSearchParams();
  const page = searchParams.get("page");
  const [loading, setLoading] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [requests, setRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRequests, setTotalRequests] = useState(0);
  const [completedRequests, setCompletedRequests] = useState(0);
  const [cancelledRequests, setCancelledRequests] = useState(0);
  const [pendingRequests, setPendingRequets] = useState(0);
  const [showRequest, setShowRequest] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const data = [
    ["Requests", "Total Requests"],
    ["Fulfilled Pickups", completedRequests / totalRequests],
    ["Pending pickups", pendingRequests / totalRequests],
    ["Failed pickups", cancelledRequests / totalRequests],
  ];

  const fetchRequests = async (perPage, page) => {
    setLoading(true);

    const status =
      filterOption === "All"
        ? ""
        : filterOption === "Completed"
        ? "completed"
        : filterOption === "Pending"
        ? "pending"
        : "cancelled";

    try {
      let response = await RequestAPI.getRequests({ perPage, page, status });
      setRequests(response.data);
      setTotalPages(response.totalPages);
      setTotalRequests(response.totalResults);
      setCompletedRequests(response.completedRequestCount);
      setPendingRequets(response.pendingRequestCount);
      setCancelledRequests(response.canceledRequestCount);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  const filterRequests = async (option) => {
    setFilterOption(option);
    setShowFilters(!showFilters);

    navigate(`/admin/requests?page=1&status=${option}`);
  };

  useEffect(() => {
    fetchRequests(5, page);
  }, [page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchRequests(5, 1);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [filterOption]);

  return (
    <div className="w-full md:w-10/12 h-screen overflow-auto">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin - Trash4Me</title>
      </Helmet>
      <LogoutModal
        show={showLogout}
        toggle={() => setShowLogout(!showLogout)}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* Notification SideBar */}
      <NotificationSideBar
        show={showNotification}
        toggle={() => setShowNotification(!showNotification)}
      />

      <RequestModal
        request={selectedRequest}
        show={showRequest}
        toggle={() => setShowRequest(!showRequest)}
      />
      <div className="flex items-center space-x-3 border-b py-5 mb-3">
        <HiClipboardDocumentCheck size="30" className="text-lime-500" />
        <p className="font-bold text-lg">Pickups</p>
        <div className="grow"></div>
        <button
          type="button"
          onClick={() => {
            document.querySelector("#mobile-menu").classList.toggle("hidden");
          }}
          className="inline-flex items-center py-2 md:px-5 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          onClick={() => setShowNotification(!showNotification)}
          className="inline-flex items-center py-2 pr-5 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-7 h-7"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.7029 24H8.29707C5.85922 24 4.46766 21.5478 5.93036 19.8295C6.5779 19.0687 6.97696 18.1665 7.08435 17.2203L7.6806 11.9669C8.00211 9.13414 10.1169 6.80955 12.9734 5.83151V5.69322C12.9734 4.02168 14.3284 2.66663 16 2.66663C17.6715 2.66663 19.0266 4.02168 19.0266 5.69322V5.83151C21.8831 6.80955 23.9979 9.13414 24.3194 11.9669L24.9156 17.2203C25.023 18.1665 25.4221 19.0687 26.0696 19.8295C27.5323 21.5478 26.1407 24 23.7029 24ZM19.9628 26.762C19.353 28.2655 17.8087 29.3333 16 29.3333C14.1913 29.3333 12.647 28.2655 12.0372 26.762C12.0117 26.6993 12 26.632 12 26.5643C12 26.2526 12.2527 26 12.5644 26H19.4356C19.7473 26 20 26.2526 20 26.5643C20 26.632 19.9882 26.6993 19.9628 26.762Z"
              fill="#28303F"
            />
          </svg>
        </button>
        <div
          className="absolute z-10 top-16 right-3 bg-gray-100 w-max hidden md:w-auto"
          id="mobile-menu"
        >
          <ul className="flex flex-col p-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-lg md:font-medium text-lime">
            <li>
              <Link
                to={{
                  pathname: "/admin/dashboard",
                }}
                className="group sidebar-icon"
              >
                <HiHome size="20" className="mr-2" />
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/users", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiUser size="20" className="mr-2" />
                Users
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/staff", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiUserGroup size="20" className="mr-2" />
                Staff
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/admin/requests",
                  search: "?page=1&status=All",
                }}
                className="group sidebar-icon"
              >
                <HiClipboardDocumentCheck size="20" className="mr-2" />
                Requests
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/admin/user-subscriptions",
                  search: "?page=1",
                }}
                className="group sidebar-icon"
              >
                <HiChatBubbleLeftEllipsis size="20" className="mr-2" />
                User Subscriptions
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/subcriptions" }}
                className="group sidebar-icon"
              >
                <HiCreditCard size="20" className="mr-2" />
                Subcriptions
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/payment-history", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiBanknotes size="20" className="mr-2" />
                Payment History
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/promotional" }}
                className="group sidebar-icon"
              >
                <HiEnvelope size="20" className="mr-2" />
                Promotional Messages
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/settings" }}
                className="group sidebar-icon"
              >
                <HiAdjustmentsHorizontal size="20" className="mr-2" />
                Settings
              </Link>
            </li>
            <li>
              <button
                onClick={() => setShowLogout(!showLogout)}
                className="logout-icon group"
              >
                <HiArrowLeftOnRectangle size="20" className="mr-2" />
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/*  */}
      <div className="md:grid grid-cols-2">
        <Chart chartType="PieChart" data={data} options={options} />
        <div className="md:grid grid-cols-2 gap-5 items-center mr-5 md:mr-10">
          <div className="h-max mb-5 md:mb-0 p-3 bg-white border border-gray-200 shadow rounded-xl flex space-x-5 items-center">
            <HiClipboardDocumentCheck size="20" className="text-gray-600" />
            <div>
              <p className="text-gray-600">Total Pickups</p>
              <p className="font-bold">{totalRequests}</p>
            </div>
          </div>
          <div className="h-max mb-5 md:mb-0 p-3 bg-lime-400 text-white border border-gray-200 shadow rounded-xl flex space-x-5 items-center">
            <HiClipboardDocumentCheck size="20" />
            <div>
              <p>Completed Pickups</p>
              <p className="font-bold">{completedRequests}</p>
            </div>
          </div>
          <div className="h-max mb-5 md:mb-0 p-3 bg-amber-400 text-white border border-gray-200 shadow rounded-xl flex space-x-5 items-center">
            <HiClipboardDocumentCheck size="20" />
            <div>
              <p>Pending Pickups</p>
              <p className="font-bold">{pendingRequests}</p>
            </div>
          </div>
          <div className="h-max p-3 bg-red-500 text-white border border-gray-200 shadow rounded-xl flex space-x-5 items-center">
            <HiClipboardDocumentCheck size="20" />
            <div>
              <p>Failed Pickups</p>
              <p className="font-bold">{cancelledRequests}</p>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="relative shadow-md md:rounded-lg mr-5 md:mr-10 my-5">
        <div className="flex space-x-3 items-center pb-4">
          <div>
            <button
              id="dropdownRadioButton"
              onClick={() => setShowFilters(!showFilters)}
              className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              type="button"
            >
              <HiClipboardDocumentCheck className="mr-2" />
              {filterOption}
              <svg
                className="ml-2 w-3 h-3"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
            <div
              id="dropdownRadio"
              className={`${
                showFilters ? "" : "hidden"
              } absolute z-10 w-48 bg-white rounded divide-y divide-gray-100 shadow`}
            >
              <ul
                className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownRadioButton"
              >
                {filterOptions.map((option, index) => (
                  <li key={index}>
                    <div
                      onClick={() => filterRequests(option)}
                      className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                    >
                      <label
                        for="filter-radio-example-1"
                        className="ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                      >
                        {option}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="grow"></div>
          <Button disabled={true} isLoading={false} label="Export as CSV" />
        </div>

        {/* Requests Table */}
        {loading ? (
          <Spinner className="w-8 h-8 m-5 " text="" />
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full table-auto text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6 whitespace-nowrap">
                    ID
                  </th>
                  <th scope="col" className="py-3 px-6 whitespace-nowrap">
                    USER
                  </th>
                  <th scope="col" className="py-3 px-6 whitespace-nowrap">
                    ADDRESS
                  </th>
                  <th scope="col" className="py-3 px-6 whitespace-nowrap">
                    STATUS
                  </th>
                  <th scope="col" className="py-3 px-6 whitespace-nowrap">
                    DATE
                  </th>
                  <th scope="col" className="py-3 px-6 whitespace-nowrap">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {requests.map((request, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b hover:bg-gray-50"
                  >
                    <th
                      scope="row"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {`RQ-${request.requestId}`}
                    </th>
                    <td className="py-4 px-6 whitespace-nowrap">
                      {request.clientId.fullName}
                    </td>
                    <td className="py-4 px-6 whitespace-nowrap">
                      {request.clientId.address.fullAddress}
                    </td>
                    <td className="py-4 px-6 flex items-center whitespace-nowrap">
                      <p
                        className={`px-4 rounded-xl ${
                          request.status !== "completed" &&
                          request.status !== "cancelled"
                            ? "bg-yellow-200 text-yellow-500"
                            : request.status === "completed"
                            ? "bg-lime-200 text-lime-500"
                            : "bg-gray-200 text-gray-500"
                        }`}
                      >
                        {request.status}
                      </p>
                    </td>
                    <td className="py-4 px-6 whitespace-nowrap">
                      {moment(new Date(request.dateOfPickup)).format(
                        "MMMM Do YYYY"
                      )}
                    </td>
                    <td className="py-4 ml-3">
                      <button
                        onClick={() => {
                          setSelectedRequest(request);
                          setShowRequest(!showRequest);
                        }}
                        className="font-medium text-lime-600 hover:underline"
                      >
                        View request
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Footer */}
        {loading ? (
          <></>
        ) : (
          <nav className="flex justify-between items-center mx-10 mt-5">
            <span className="text-sm font-normal text-gray-500">
              Showing{" "}
              <span className="font-semibold text-gray-900">
                {page * 5 - 4} -{" "}
                {page * 5 > totalRequests ? totalRequests : page * 5}
              </span>{" "}
              of{" "}
              <span className="font-semibold text-gray-900">
                {totalRequests}
              </span>
            </span>
            <ul className="inline-flex items-center -space-x-px">
              {page === "1" ? (
                <></>
              ) : (
                <li>
                  <Link
                    to={{
                      pathname: "/admin/requests",
                      search: `?page=${
                        parseInt(page) - 1
                      }&status=${filterOption}`,
                    }}
                    className="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                  >
                    <span className="sr-only">Previous</span>
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </Link>
                </li>
              )}
              {parseInt(page) === totalPages ? (
                <></>
              ) : (
                <li>
                  <Link
                    to={{
                      pathname: "/admin/requests",
                      search: `?page=${
                        parseInt(page) + 1
                      }&status=${filterOption}`,
                    }}
                    className="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                  >
                    <span className="sr-only">Next</span>
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
}
