import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import StaffAPI from "../../apis/staffAPI";
import Toast from "../../utils/toast";
import EditStaffModal from "../../components/modals/EditStaffModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import LogoutModal from "../../components/modals/LogoutModal";
import {
  HiHome,
  HiArrowLeftOnRectangle,
  HiAdjustmentsHorizontal,
  HiUser,
} from "react-icons/hi2";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../app/user";
import NotificationSideBar from "../../components/NotificationSideBar";

export default function StaffProfile() {
  let navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [showLogout, setShowLogout] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [totalRequests, setTotalRequests] = useState(0);
  const fileUpload = useRef(null);

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      let response = await StaffAPI.getRequests();
      setRating(response.averageRating);
      setTotalRequests(response.totalResults);
    } catch (err) {}
  };

  const uploadProfilePic = async (e) => {
    e.preventDefault();
    setLoading(true);

    const file = e.target.files[0];

    try {
      let response = await StaffAPI.updateProfilePicture(file);
      dispatch(setUser(response.data));
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    fileUpload.current.click();
  };

  return (
    <div className="w-full md:w-10/12 h-screen overflow-auto">
      <LogoutModal
        show={showLogout}
        toggle={() => setShowLogout(!showLogout)}
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Staff - Trash4Me</title>
      </Helmet>
      <EditStaffModal
        show={showModal}
        toggle={() => setShowModal(!showModal)}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* Notification SideBar */}
      <NotificationSideBar
        show={showNotification}
        toggle={() => setShowNotification(!showNotification)}
      />

      <div className="flex items-center space-x-3 border-b py-5">
        <HiUser size="30" className="text-lime-500" />
        <p className="font-bold text-lg">Profile</p>
        <div className="grow"></div>
        <button
          type="button"
          onClick={() => {
            document.querySelector("#mobile-menu").classList.toggle("hidden");
          }}
          className="inline-flex items-center py-2 md:px-5 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          onClick={() => setShowNotification(!showNotification)}
          className="inline-flex items-center py-2 pr-5 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-7 h-7"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.7029 24H8.29707C5.85922 24 4.46766 21.5478 5.93036 19.8295C6.5779 19.0687 6.97696 18.1665 7.08435 17.2203L7.6806 11.9669C8.00211 9.13414 10.1169 6.80955 12.9734 5.83151V5.69322C12.9734 4.02168 14.3284 2.66663 16 2.66663C17.6715 2.66663 19.0266 4.02168 19.0266 5.69322V5.83151C21.8831 6.80955 23.9979 9.13414 24.3194 11.9669L24.9156 17.2203C25.023 18.1665 25.4221 19.0687 26.0696 19.8295C27.5323 21.5478 26.1407 24 23.7029 24ZM19.9628 26.762C19.353 28.2655 17.8087 29.3333 16 29.3333C14.1913 29.3333 12.647 28.2655 12.0372 26.762C12.0117 26.6993 12 26.632 12 26.5643C12 26.2526 12.2527 26 12.5644 26H19.4356C19.7473 26 20 26.2526 20 26.5643C20 26.632 19.9882 26.6993 19.9628 26.762Z"
              fill="#28303F"
            />
          </svg>
        </button>
        <div
          className="absolute z-10 top-16 right-3 bg-gray-100 w-max hidden md:w-auto"
          id="mobile-menu"
        >
          <ul className="flex flex-col p-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-lg md:font-medium text-lime">
            <li>
              <Link
                to={{
                  pathname: "/staff/dashboard",
                  search: "?tab=All%20Requests",
                }}
                className="group sidebar-icon"
              >
                <HiHome size="20" className="mr-2" />
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/staff/profile" }}
                className="group sidebar-icon"
              >
                <HiUser size="20" className="mr-2" />
                Profile
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/staff/settings" }}
                className="group sidebar-icon"
              >
                <HiAdjustmentsHorizontal size="20" className="mr-2" />
                Settings
              </Link>
            </li>
            <li>
              <button
                onClick={() => setShowLogout(!showLogout)}
                className="logout-icon group"
              >
                <HiArrowLeftOnRectangle size="20" className="mr-2" />
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="md:flex space-x-5 p-5">
        <div className="flex flex-col items-center">
          <input
            type="file"
            ref={fileUpload}
            onChange={uploadProfilePic}
            accept="image/*"
            style={{ opacity: "0" }}
          />
          <div className="relative z-0">
            <img
              onClick={handleUpload}
              className="rounded-full w-36 h-36"
              src={user.imageUrl}
              alt={user.fullName}
            />
            {loading ? (
              <svg
                className="animate-spin h-5 w-5 text-lime mr-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <></>
            )}
          </div>
          <div className="flex  space-x-5 items-center justify-center mt-3">
            <div className="text-center">
              <p className="text-gray-400">Your Rating</p>
              <p className="font-bold text-lg">{rating}%</p>
            </div>
            <div className="text-center">
              <p className="text-gray-400">Total Pickups</p>
              <p className="font-bold text-lg">{totalRequests}</p>
            </div>
          </div>
        </div>
        <div className="grow mt-5 md:mt-0">
          <div className="border-b pb-2 flex space-x-4">
            <div>
              <p className="font-bold text-2xl">{user.fullName}</p>
              <p className="text-md">{user.address?.fullAddress}</p>
            </div>
            <div className="grow"></div>
            <button
              onClick={() => setShowModal(!showModal)}
              type="button"
              className="text-white bg-lime-700 hover:bg-lime-800 focus:ring-4 focus:outline-none focus:ring-lime-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-5 h-5 mr-2 -ml-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
              Edit
            </button>
          </div>
          <div className="py-5">
            <div className="flex space-x-3">
              <p className="text-base text-gray-600">Email:</p>
              <p className="text-lg col-span-5">{user.email}</p>
            </div>
            <div className="flex space-x-3">
              <p className="text-base text-gray-600">Phone Number:</p>
              <p className="text-lg col-span-5">{user.phoneNumber}</p>
            </div>
            <div className="flex space-x-3">
              <p className="text-base text-gray-600">Gender:</p>
              <p className="text-lg col-span-5">{user.gender || "Nill"}</p>
            </div>
            <div className="flex space-x-3">
              <p className="text-base text-gray-600">Date of birth:</p>
              <p className="text-lg col-span-5">{user.dob || "Nill"}</p>
            </div>
            <div className="flex space-x-3">
              <p className="text-base text-gray-600">City:</p>
              <p className="text-lg col-span-5">
                {user.address?.city || "Nill"}
              </p>
            </div>
            <div className="flex space-x-3">
              <p className="text-base text-gray-600">State:</p>
              <p className="text-lg col-span-5">
                {user.address?.state || "Nill"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
