import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserAPI from "../../apis/userAPI";
import { Helmet } from "react-helmet";
import {
  HiHome,
  HiChatBubbleLeftEllipsis,
  HiEnvelope,
  HiUser,
  HiCreditCard,
  HiBanknotes,
  HiAdjustmentsHorizontal,
  HiArrowLeftOnRectangle,
  HiClipboardDocumentCheck,
  HiUserGroup,
} from "react-icons/hi2";
import UserModal from "../../components/modals/UserModal";
import { Spinner } from "loading-animations-react";
import LogoutModal from "../../components/modals/LogoutModal";
import NotificationSideBar from "../../components/NotificationSideBar";

export default function Users() {
  let navigate = useNavigate();
  let [searchParams, _] = useSearchParams();
  const page = searchParams.get("page");
  const [showLogout, setShowLogout] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showUser, setShowUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [totalUsers, setTotalUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchUsers = async (perPage, page, name) => {
    setLoading(true);

    try {
      let response = await UserAPI.getUsers({ perPage, page, name });
      setUsers(response.data);
      setTotalPages(response.totalPages);
      setTotalUsers(response.totalResults);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(5, page, searchQuery);
  }, [page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchUsers(5, 1, searchQuery);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  return (
    <div className="w-full md:w-10/12 h-screen overflow-auto">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin - Trash4Me</title>
      </Helmet>
      <LogoutModal
        show={showLogout}
        toggle={() => setShowLogout(!showLogout)}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* Notification SideBar */}
      <NotificationSideBar
        show={showNotification}
        toggle={() => setShowNotification(!showNotification)}
      />

      <UserModal
        user={selectedUser}
        show={showUser}
        toggle={(refresh) => {
          setShowUser(!showUser);
          if (refresh) {
            fetchUsers(5, 1, searchQuery);
          }
        }}
      />
      <div className="flex items-center space-x-3 border-b py-3 mr-10">
        <HiUser size="30" className="text-lime-500" />
        <p className="font-bold text-lg">Users</p>
        <div className="grow"></div>
        <label for="table-search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            id="table-search-users"
            className="block p-2 pl-10 w-52 md:w-96 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-lime-500 focus:border-lime-500 "
            placeholder="Search for users"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <button
          type="button"
          onClick={() => {
            document.querySelector("#mobile-menu").classList.toggle("hidden");
          }}
          className="inline-flex items-center py-2 md:px-5 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          onClick={() => setShowNotification(!showNotification)}
          className="inline-flex items-center py-2 pr-5 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-7 h-7"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.7029 24H8.29707C5.85922 24 4.46766 21.5478 5.93036 19.8295C6.5779 19.0687 6.97696 18.1665 7.08435 17.2203L7.6806 11.9669C8.00211 9.13414 10.1169 6.80955 12.9734 5.83151V5.69322C12.9734 4.02168 14.3284 2.66663 16 2.66663C17.6715 2.66663 19.0266 4.02168 19.0266 5.69322V5.83151C21.8831 6.80955 23.9979 9.13414 24.3194 11.9669L24.9156 17.2203C25.023 18.1665 25.4221 19.0687 26.0696 19.8295C27.5323 21.5478 26.1407 24 23.7029 24ZM19.9628 26.762C19.353 28.2655 17.8087 29.3333 16 29.3333C14.1913 29.3333 12.647 28.2655 12.0372 26.762C12.0117 26.6993 12 26.632 12 26.5643C12 26.2526 12.2527 26 12.5644 26H19.4356C19.7473 26 20 26.2526 20 26.5643C20 26.632 19.9882 26.6993 19.9628 26.762Z"
              fill="#28303F"
            />
          </svg>
        </button>
        <div
          className="absolute z-10 top-16 right-3 bg-gray-100 w-max hidden md:w-auto"
          id="mobile-menu"
        >
          <ul className="flex flex-col p-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-lg md:font-medium text-lime">
            <li>
              <Link
                to={{
                  pathname: "/admin/dashboard",
                }}
                className="group sidebar-icon"
              >
                <HiHome size="20" className="mr-2" />
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/users", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiUser size="20" className="mr-2" />
                Users
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/staff", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiUserGroup size="20" className="mr-2" />
                Staff
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/requests", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiClipboardDocumentCheck size="20" className="mr-2" />
                Requests
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/admin/user-subscriptions",
                  search: "?page=1",
                }}
                className="group sidebar-icon"
              >
                <HiChatBubbleLeftEllipsis size="20" className="mr-2" />
                User Subscriptions
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/subcriptions" }}
                className="group sidebar-icon"
              >
                <HiCreditCard size="20" className="mr-2" />
                Subcriptions
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/payment-history", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiBanknotes size="20" className="mr-2" />
                Payment History
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/promotional" }}
                className="group sidebar-icon"
              >
                <HiEnvelope size="20" className="mr-2" />
                Promotional Messages
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/settings" }}
                className="group sidebar-icon"
              >
                <HiAdjustmentsHorizontal size="20" className="mr-2" />
                Settings
              </Link>
            </li>
            <li>
              <button
                onClick={() => setShowLogout(!showLogout)}
                className="logout-icon group"
              >
                <HiArrowLeftOnRectangle size="20" className="mr-2" />
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* Users Table */}
      {loading ? (
        <Spinner className="w-8 h-8 m-5 " text="" />
      ) : (
        <div className="relative overflow-x-auto shadow-md md:rounded-lg md:mr-10 my-3">
          <table className="w-full table-auto text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="py-3 px-6">
                  Name
                </th>
                <th scope="col" className="py-3 px-6">
                  Address
                </th>
                <th scope="col" className="py-3 px-6">
                  Status
                </th>
                <th scope="col" className="py-3 px-6">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((element, index) => (
                <tr className="bg-white border-b hover:bg-gray-200">
                  <td
                    scope="row"
                    className="mr-20 md:mr-0 flex items-center py-4 text-gray-900 whitespace-nowrap"
                  >
                    <img
                      className="w-10 h-10 rounded-full"
                      src={element.imageUrl}
                      alt={element.fullName}
                    />
                    <div className="ml-3">
                      <div className="text-base font-semibold">
                        {element.fullName}
                      </div>
                      <div className="font-normal text-gray-500">
                        {element.email}
                      </div>
                    </div>
                  </td>
                  <td className="p-4 whitespace-nowrap">
                    <div className="flex items-center">
                      {element.address.fullAddress}
                    </div>
                  </td>
                  <td className="p-4">
                    <div className="flex items-center">
                      {element.isPremium ? "Premium" : "Normal"}
                    </div>
                  </td>
                  <td className="p-4">
                    <button
                      onClick={() => {
                        setSelectedUser(element);
                        setShowUser(!showUser);
                      }}
                      className="font-medium text-lime-600 text-center whitespace-nowrap"
                    >
                      View user
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Footer */}
      {loading ? (
        <></>
      ) : (
        <nav className="flex justify-between items-center mx-10">
          <span className="text-sm font-normal text-gray-500">
            Showing{" "}
            <span className="font-semibold text-gray-900">
              {page * 5 - 4} - {page * 5 > totalUsers ? totalUsers : page * 5}
            </span>{" "}
            of <span className="font-semibold text-gray-900">{totalUsers}</span>
          </span>
          <ul className="inline-flex items-center -space-x-px">
            {page === "1" ? (
              <></>
            ) : (
              <li>
                <Link
                  to={{
                    pathname: "/admin/users",
                    search: `?page=${parseInt(page) - 1}`,
                  }}
                  className="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </li>
            )}
            {parseInt(page) === totalPages ? (
              <></>
            ) : (
              <li>
                <Link
                  to={{
                    pathname: "/admin/users",
                    search: `?page=${parseInt(page) + 1}`,
                  }}
                  className="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  );
}
