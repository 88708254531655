import React, { useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/logo.png";
import Button from "../../components/Button";
import PasswordField from "../../components/PasswordField";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppAPI from "../../apis/appAPI";

export default function ResetPassword() {
  let navigate = useNavigate();
  let [searchParams, _] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const validateFields = (e) => {
    e.preventDefault();

    if (
      !password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
      )
    ) {
      setPasswordError(true);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      return;
    }

    resetPassword();
  };

  const resetPassword = async () => {
    const token = searchParams.get("token");
    console.log(token);
    setLoading(true);
    let data = { token, password, confirmPassword };

    try {
      let response = await AppAPI.resetPassword(data);
      Toast.success(response.message);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reset Password - Trash4Me</title>
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <nav className=" px-5 md:px-10 pt-2 border-b-2">
        <div className="container flex flex-wrap justify-between items-center mx-auto px-5 md:px-10 pb-4 ">
          <a href="/" className="flex items-center">
            <img src={logo} alt="" width={150} />
          </a>
        </div>
      </nav>
      <div className="container justify-center mx-auto py-10 w-fit px-5">
        <p className="text-6xl text-center">Reset Password</p>
        <form>
          <PasswordField
            id="password"
            label="Password"
            required={true}
            value={password}
            error={passwordError}
            errorMessage="Please enter your Password"
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError(false);
            }}
          />
          <PasswordField
            id="confirm_password"
            label="Confirm password"
            required={true}
            value={confirmPassword}
            error={confirmPasswordError}
            errorMessage="Password must contain at least 1 lowercase, 1 uppercase, 1 numeric, one special character and must be eight characters or longer"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setConfirmPasswordError(false);
            }}
          />
          <Button
            onClick={validateFields}
            disabled={false}
            isLoading={loading}
            label="Reset Password"
          />
        </form>
        <Link to="/login" className="text-medium">
          Remembered Password?{" "}
          <span className="font-bold text-lime-700">Login</span>
        </Link>
      </div>
    </div>
  );
}
