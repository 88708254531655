import api from "../utils/axios";

const RequestAPI = {
  getRequests: async function (data) {
    const perPage = data?.perPage || 5;
    const page = data?.page || 1;
    const status = data?.status || "";

    const response = await api.request({
      url: `requests?perPage=${perPage}&page=${page}&status=${status}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
};

export default RequestAPI;
