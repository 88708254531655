import React, { useState, useEffect } from "react";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import FormInput from "../FormInput";
import SelectInput from "../SelectInput";
import Button from "../../components/Button";
import StaffAPI from "../../apis/staffAPI";
import AppAPI from "../../apis/appAPI";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../app/user";

export default function EditStaffModal({ show, toggle }) {
  let navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [fullAddress, setFullAddress] = useState(
    user.address?.fullAddress || ""
  );
  const [city, setCity] = useState(user.address?.city || "");
  const [state, setState] = useState(user.address?.state || "");
  const [dob, setDOB] = useState(user.dob || "");
  const [gender, setGender] = useState(user.gender || "");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [fullAddressError, setFullAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [dobError, setDOBError] = useState(false);
  const [genderError, setGenderError] = useState(false);

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      let response = await AppAPI.getStates();
      setStates(response);
    } catch (err) {}
  };

  const validateFields = (e) => {
    e.preventDefault();
    console.log(localStorage.getItem("token"));
    if (firstName.length < 2) {
      setFirstNameError(true);
      return;
    }

    if (lastName.length < 2) {
      setLastNameError(true);
      return;
    }

    if (fullAddress.length < 2) {
      setFullAddressError(true);
      return;
    }

    if (!state) {
      setStateError(true);
      return;
    }

    if (!city) {
      setCityError(true);
      return;
    }

    if (!gender) {
      setGenderError(true);
      return;
    }

    if (!dob) {
      setDOBError(true);
      return;
    }

    update();
  };

  const update = async () => {
    setLoading(true);
    let data = {
      firstName,
      lastName,
      gender,
      dob,
      fullAddress,
      state,
      city,
    };

    try {
      let response = await StaffAPI.updateStaff(data);
      dispatch(setUser(response.data));
      Toast.success(response.message);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={
        show
          ? "w-full bg-black bg-opacity-20 fixed z-30 inset-0"
          : "hidden w-full bg-black bg-opacity-20 fixed z-30 inset-0"
      }
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        id="staticModal"
        data-modal-backdrop="static"
        tabindex="-1"
        aria-hidden="true"
        className="max-w-2xl mt-20 overflow-y-auto overflow-x-hidden fixed z-50 p-4 w-full md:inset-0 h-modal mx-auto md:h-full"
      >
        <div className="relative w-full max-w-2xl h-full md:h-auto">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow">
            {/* Modal header */}
            <div className="flex items-start p-4 rounded-t">
              <button
                onClick={() => toggle()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-red-500 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                data-modal-toggle="staticModal"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="px-6 pb-4">
              <div className="md:grid grid-cols-2 gap-x-5 gap-y-2">
                <FormInput
                  id="first_name"
                  label="First Name"
                  placeholder="John"
                  required={true}
                  type="text"
                  value={firstName}
                  error={firstNameError}
                  errorMessage="Please enter your first name"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setFirstNameError(false);
                  }}
                />
                <FormInput
                  id="last_name"
                  label="Last Name"
                  placeholder="Doe"
                  required={true}
                  type="text"
                  value={lastName}
                  error={lastNameError}
                  errorMessage="Please enter your last name"
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setLastNameError(false);
                  }}
                />
                <FormInput
                  id="full_address"
                  label="Address"
                  placeholder="no 1 Bende road, Umuahia Abia State"
                  required={true}
                  type="text"
                  value={fullAddress}
                  error={fullAddressError}
                  errorMessage="Please enter your address"
                  onChange={(e) => {
                    setFullAddress(e.target.value);
                    setFullAddressError(false);
                  }}
                />
                <SelectInput
                  id="states"
                  label="Select State"
                  value={state}
                  error={stateError}
                  errorMessage="Please select state of origin"
                  options={states.map((state) => state.name)}
                  placeholder="Choose a State"
                  onChange={(e) => {
                    setState(e.target.value);
                    const selectedStateIndex = states.findIndex(
                      (state) => state.name === e.target.value
                    );
                    const selectedState = states[selectedStateIndex];
                    setCities(selectedState.cities);
                    setStateError(false);
                  }}
                />
                <SelectInput
                  id="cities"
                  label="Select City"
                  value={city}
                  error={cityError}
                  errorMessage="Please select city of origin"
                  options={cities.map((city) => city.name)}
                  placeholder="Choose a City"
                  onChange={(e) => {
                    setCity(e.target.value);
                    setCityError(false);
                  }}
                />
                <SelectInput
                  id="gender"
                  label="Select Gender"
                  value={gender}
                  error={genderError}
                  errorMessage="Please select gender"
                  options={["Male", "Female"]}
                  placeholder="Choose a Gender"
                  onChange={(e) => {
                    setGender(e.target.value);
                    setGenderError(false);
                  }}
                />
                <FormInput
                  id="dob"
                  label="Date of Birth"
                  placeholder="12/12/2012"
                  required={true}
                  type="date"
                  value={dob}
                  error={dobError}
                  errorMessage="Please enter your date of birth"
                  onChange={(e) => {
                    setDOB(e.target.value);
                    setDOBError(false);
                  }}
                />
              </div>
              <Button
                onClick={validateFields}
                disabled={false}
                isLoading={loading}
                label="Update"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
