import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  HiHome,
  HiClipboardDocumentCheck,
  HiArrowLeftOnRectangle,
  HiAdjustmentsHorizontal,
  HiUser,
} from "react-icons/hi2";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import OutlineButton from "../../components/OutlineButton";
import StaffAPI from "../../apis/staffAPI";
import RequestAPI from "../../apis/requestAPI";
import LogoutModal from "../../components/modals/LogoutModal";
import NotificationSideBar from "../../components/NotificationSideBar";

export default function StaffDashboard() {
  let navigate = useNavigate();
  let [searchParams, _] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const selectedTab = searchParams.get("tab") || "All Requests";
  const [showLogout, setShowLogout] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [requests, setRequests] = useState([]);
  const [myRequests, setMyRequests] = useState([]);
  const [myActiveRequests, setMyActiveRequests] = useState([]);
  const [myCompletedRequets, setMyCompletedRequests] = useState([]);
  const [myCancelledRequets, setMyCancelledRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRequests, setTotalRequests] = useState(0);
  const [loading, setLoading] = useState(null);
  const [showStatus, setShowStatus] = useState(null);
  const statusOptions = ["Ongoing", "Completed", "Cancelled"];
  const tabs = [
    {
      name: "All Requests",
      icon: (
        <svg
          aria-hidden="true"
          className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
            clip-rule="evenodd"
          ></path>
        </svg>
      ),
    },
    // {
    //   name: "My Requests",
    //   icon: (
    //     <svg
    //       aria-hidden="true"
    //       className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500"
    //       fill="currentColor"
    //       viewBox="0 0 20 20"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fill-rule="evenodd"
    //         d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
    //         clip-rule="evenodd"
    //       ></path>
    //     </svg>
    //   ),
    // },
    {
      name: "Active Requests",
      icon: (
        <svg
          aria-hidden="true"
          className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
            clip-rule="evenodd"
          ></path>
        </svg>
      ),
    },
    {
      name: "Completed Requests",
      icon: (
        <svg
          aria-hidden="true"
          className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
            clip-rule="evenodd"
          ></path>
        </svg>
      ),
    },
    {
      name: "Cancelled Requests",
      icon: (
        <svg
          aria-hidden="true"
          className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
            clip-rule="evenodd"
          ></path>
        </svg>
      ),
    },
  ];

  useEffect(() => {
    fetchMyRequests();
    fetchRequests();
  }, []);

  const fetchMyRequests = async () => {
    try {
      let response = await StaffAPI.getRequests();
      setMyRequests(response.data);
      setMyActiveRequests(
        response.data.filter(
          (element) =>
            element.status !== "completed" && element.status !== "cancelled"
        )
      );
      setMyCompletedRequests(
        response.data.filter((element) => element.status === "completed")
      );
      setMyCancelledRequests(
        response.data.filter((element) => element.status === "cancelled")
      );
    } catch (err) {}
  };

  const fetchRequests = async () => {
    try {
      let response = await RequestAPI.getRequests();
      setRequests(response.data);
      setTotalPages(response.totalPages);
      let available = response.data.filter((req) => {
        return req.status === "created";
      });
      setTotalRequests(available.length);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    }
  };

  const assign = async (request) => {
    setLoading(request._id);
    let data = {
      requestId: request._id,
    };

    try {
      await StaffAPI.assign(data);
      setRequests(requests.filter((data) => data._id !== request._id));
      setMyRequests([...myRequests, request]);
      setMyActiveRequests([...myActiveRequests, request]);
      fetchMyRequests();
      fetchRequests();
      Toast.success("Request Assigned to you");
      navigate("/staff/dashboard?tab=My%20Requests");
    } catch (err) {
      Toast.error(err.response.data.message);
    } finally {
      setLoading(null);
    }
  };

  const updateStatus = async (request, status) => {
    setShowStatus(!showStatus);
    setLoading(request._id);
    let data = {
      requestId: request._id,
      status: status.toLowerCase(),
    };

    try {
      await StaffAPI.updateStatus(data);
      await fetchMyRequests();
      Toast.success("Request Updated");
    } catch (err) {
      Toast.error(err.response.data.message);
    } finally {
      setLoading(null);
    }
  };

  return (
    <div className="w-full md:w-10/12 h-screen overflow-auto">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Staff - Trash4Me</title>
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* Notification SideBar */}
      <NotificationSideBar
        show={showNotification}
        toggle={() => setShowNotification(!showNotification)}
      />

      <LogoutModal
        show={showLogout}
        toggle={() => setShowLogout(!showLogout)}
      />
      <div className="flex items-center space-x-3 border-b py-5">
        <HiHome size="30" className="text-lime-500" />
        <p className="font-bold text-lg">Dashboard</p>
        <div className="grow"></div>
        <button
          type="button"
          onClick={() => {
            document.querySelector("#mobile-menu").classList.toggle("hidden");
          }}
          className="inline-flex items-center py-2 sm:px-5 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          onClick={() => setShowNotification(!showNotification)}
          className="inline-flex items-center py-2 pr-5 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-7 h-7"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.7029 24H8.29707C5.85922 24 4.46766 21.5478 5.93036 19.8295C6.5779 19.0687 6.97696 18.1665 7.08435 17.2203L7.6806 11.9669C8.00211 9.13414 10.1169 6.80955 12.9734 5.83151V5.69322C12.9734 4.02168 14.3284 2.66663 16 2.66663C17.6715 2.66663 19.0266 4.02168 19.0266 5.69322V5.83151C21.8831 6.80955 23.9979 9.13414 24.3194 11.9669L24.9156 17.2203C25.023 18.1665 25.4221 19.0687 26.0696 19.8295C27.5323 21.5478 26.1407 24 23.7029 24ZM19.9628 26.762C19.353 28.2655 17.8087 29.3333 16 29.3333C14.1913 29.3333 12.647 28.2655 12.0372 26.762C12.0117 26.6993 12 26.632 12 26.5643C12 26.2526 12.2527 26 12.5644 26H19.4356C19.7473 26 20 26.2526 20 26.5643C20 26.632 19.9882 26.6993 19.9628 26.762Z"
              fill="#28303F"
            />
          </svg>
        </button>
        <div
          className="absolute z-10 top-16 right-3 bg-gray-100 w-max hidden md:w-auto"
          id="mobile-menu"
        >
          <ul className="flex flex-col p-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-lg md:font-medium text-lime">
            <li>
              <Link
                to={{
                  pathname: "/staff/dashboard",
                  search: "?tab=All%20Requests",
                }}
                className="group sidebar-icon"
              >
                <HiHome size="20" className="mr-2" />
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/staff/profile" }}
                className="group sidebar-icon"
              >
                <HiUser size="20" className="mr-2" />
                Profile
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/staff/settings" }}
                className="group sidebar-icon"
              >
                <HiAdjustmentsHorizontal size="20" className="mr-2" />
                Settings
              </Link>
            </li>
            <li>
              <button
                onClick={() => setShowLogout(!showLogout)}
                className="logout-icon group"
              >
                <HiArrowLeftOnRectangle size="20" className="mr-2" />
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200">
        <ul className="flex w-full overflow-auto -mb-px text-sm font-medium text-center text-gray-500">
          {tabs.map((tab, index) => (
            <li key={index} className="mr-2">
              <Link
                to={{
                  pathname: "/staff/dashboard",
                  search: `?tab=${tab.name}`,
                }}
                className={
                  selectedTab === tab.name
                    ? "inline-flex p-4 text-lime-600 border-b-2 border-lime-600 rounded-t-lg active group"
                    : "inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group"
                }
              >
                {tab.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      {/* Requests List */}
      {selectedTab === "All Requests" ? (
        requests.map((request, index) => {
          return request.status !== "created" ? (
            <p></p>
          ) : (
            <div
              key={index}
              className="flex space-x-5 border-b border-gray-200 p-5"
            >
              <HiClipboardDocumentCheck size="20" className="mr-2" />
              <div className="grow">
                <span
                  className={
                    !request.staffId
                      ? "inline-flex items-center bg-lime-100 text-lime-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                      : "inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                  }
                >
                  <span
                    className={
                      !request.staffId
                        ? "w-2 h-2 mr-1 bg-lime-500 rounded-full"
                        : "w-2 h-2 mr-1 bg-red-500 rounded-full"
                    }
                  ></span>
                  {!request.staffId ? "Available" : "Unavailable"}
                </span>
                <p className="my-1 text-lg">RQ-{request.requestId}</p>
                <p className="font-bold text-base">
                  {request.clientId.fullName}
                </p>
                <p className="text-gray-500 text-sm">
                  Phone Number: {request.clientId.phoneNumber}
                </p>
                <p className="text-gray-500 text-sm">City: {request.city}</p>
                <p className="text-gray-500 text-sm">State: {request.state}</p>
                <p className="text-gray-500 text-sm">
                  Address: {request.address}
                </p>
                <p className="text-gray-500 text-sm">
                  {request.numberOfBags} Standard Refuse Bags
                </p>
                <p className="text-gray-500 text-sm">
                  {moment(new Date(request.dateOfPickup)).format(
                    "MMMM Do YYYY"
                  )}
                </p>
              </div>
              {!request.staffId ? (
                <Button
                  label="Assign to me"
                  isLoading={loading === request._id}
                  onClick={() => assign(request)}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })
      ) : (
        <></>
      )}

      {/* My Requests List */}
      {/* {selectedTab === "My Requests" ? (
        myRequests.map((request, index) => {
          console.log(myRequests);
          return request.status !== "completed" ||
            request.status !== "cancelled" ? (
            <></>
          ) : (
            <div
              key={index}
              className="flex space-x-5 border-b border-gray-200 p-5"
            >
              <HiClipboardDocumentCheck size="20" className="mr-2" />
              <div className="grow">
                <span
                  className={
                    request.status === "completed"
                      ? "inline-flex items-center bg-lime-100 text-lime-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                      : request.status === "cancelled"
                      ? "inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                      : "inline-flex items-center bg-yellow-100 text-yellow-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                  }
                >
                  <span
                    className={
                      request.status === "completed"
                        ? "w-2 h-2 mr-1 bg-lime-500 rounded-full"
                        : request.status === "cancelled"
                        ? "w-2 h-2 mr-1 bg-red-500 rounded-full"
                        : "w-2 h-2 mr-1 bg-yellow-400 rounded-full"
                    }
                  ></span>
                  {request.status}
                </span>
                <p className="my-1 text-lg">RQ-{request.requestId}</p>
                <p className="font-bold text-base">
                  {request.clientId.fullName}
                </p>
                <p className="text-gray-500 text-sm">
                  Phone Number: {request.clientId.phoneNumber}
                </p>
                <p className="text-gray-500 text-sm">City: {request.city}</p>
                <p className="text-gray-500 text-sm">State: {request.state}</p>
                <p className="text-gray-500 text-sm">
                  Address: {request.address}
                </p>
                <p className="text-gray-500 text-sm">
                  {request.numberOfBags} Standard Refuse Bags
                </p>
                <p className="text-gray-500 text-sm">
                  {moment(new Date(request.dateOfPickup)).format(
                    "MMMM Do YYYY"
                  )}
                </p>
              </div>
              <div>
                <OutlineButton
                  label="Update Status"
                  isLoading={loading === request._id}
                  onClick={() =>
                    showStatus
                      ? setShowStatus(null)
                      : setShowStatus(request._id)
                  }
                />
                <div
                  id="status"
                  className={`${
                    showStatus === request._id ? "" : "hidden"
                  } z-10 w-max bg-white rounded divide-y divide-gray-100 shadow`}
                >
                  <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
                    {statusOptions.map((option, index) => (
                      <li key={index}>
                        <div
                          onClick={() => updateStatus(request, option)}
                          className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                        >
                          <label
                            for="filter-radio-example-1"
                            className="ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                          >
                            {option}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )} */}

      {/* My Active Requests List */}
      {selectedTab === "Active Requests" ? (
        myActiveRequests.map((request, index) => (
          <div
            key={index}
            className="flex space-x-5 border-b border-gray-200 p-5"
          >
            <HiClipboardDocumentCheck size="20" className="mr-2" />
            <div className="grow">
              <span
                className={
                  request.status === "completed"
                    ? "inline-flex items-center bg-lime-100 text-lime-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                    : request.status === "cancelled"
                    ? "inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                    : "inline-flex items-center bg-yellow-100 text-yellow-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                }
              >
                <span
                  className={
                    request.status === "completed"
                      ? "w-2 h-2 mr-1 bg-lime-500 rounded-full"
                      : request.status === "cancelled"
                      ? "w-2 h-2 mr-1 bg-red-500 rounded-full"
                      : "w-2 h-2 mr-1 bg-yellow-400 rounded-full"
                  }
                ></span>
                {request.status}
              </span>
              <p className="my-1 text-lg">RQ-{request.requestId}</p>
              <p className="font-bold text-base">{request.clientId.fullName}</p>
              <p className="text-gray-500 text-sm">
                Phone Number: {request.clientId.phoneNumber}
              </p>
              <p className="text-gray-500 text-sm">City: {request.city}</p>
              <p className="text-gray-500 text-sm">State: {request.state}</p>
              <p className="text-gray-500 text-sm">
                Address: {request.address}
              </p>
              <p className="text-gray-500 text-sm">
                {request.numberOfBags} Standard Refuse Bags
              </p>
              <p className="text-gray-500 text-sm">
                {moment(new Date(request.dateOfPickup)).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </p>
            </div>
            <div className="flex flex-col md:flex-row">
              <OutlineButton
                label="Update Status"
                isLoading={loading === request._id}
                onClick={() =>
                  showStatus ? setShowStatus(null) : setShowStatus(request._id)
                }
              />
              <div className="md:mt-5 ml-5">
                <a
                  href={`tel:${request.clientId.phoneNumber}`}
                  type="button"
                  class="text-lime-700 border border-lime-700 hover:bg-lime-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-lime-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-lime-500 dark:text-lime-500 dark:hover:text-white dark:focus:ring-lime-800 dark:hover:bg-lime-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                    />
                  </svg>

                  <span class="sr-only">Icon description</span>
                </a>
              </div>
            </div>
            <div
              id="status"
              className={`${
                showStatus === request._id ? "" : "hidden"
              } absolute z-10 w-48 bg-white rounded divide-y divide-gray-100 shadow`}
            >
              <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
                {statusOptions.map((option, index) => (
                  <li key={index}>
                    <div
                      onClick={() => updateStatus(request, option)}
                      className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                    >
                      <label
                        for="filter-radio-example-1"
                        className="ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                      >
                        {option}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}

      {/* My Completed Requests List */}
      {selectedTab === "Completed Requests" ? (
        myCompletedRequets.map((request, index) => (
          <div
            key={index}
            className="flex space-x-5 border-b border-gray-200 p-5"
          >
            <HiClipboardDocumentCheck size="20" className="mr-2" />
            <div className="grow">
              <span
                className={
                  request.status === "completed"
                    ? "inline-flex items-center bg-lime-100 text-lime-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                    : request.status === "cancelled"
                    ? "inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                    : "inline-flex items-center bg-yellow-100 text-yellow-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                }
              >
                <span
                  className={
                    request.status === "completed"
                      ? "w-2 h-2 mr-1 bg-lime-500 rounded-full"
                      : request.status === "cancelled"
                      ? "w-2 h-2 mr-1 bg-red-500 rounded-full"
                      : "w-2 h-2 mr-1 bg-yellow-400 rounded-full"
                  }
                ></span>
                {request.status}
              </span>
              <p className="my-1 text-lg">RQ-{request.requestId}</p>
              <p className="font-bold text-base">{request.clientId.fullName}</p>
              <p className="text-gray-500 text-sm">
                Phone Number: {request.clientId.phoneNumber}
              </p>
              <p className="text-gray-500 text-sm">City: {request.city}</p>
              <p className="text-gray-500 text-sm">State: {request.state}</p>
              <p className="text-gray-500 text-sm">
                Address: {request.address}
              </p>
              <p className="text-gray-500 text-sm">
                {request.numberOfBags} Standard Refuse Bags
              </p>
              <p className="text-gray-500 text-sm">
                {moment(new Date(request.dateOfPickup)).format("MMMM Do YYYY")}
              </p>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}

      {/* My Cancelled Requests List */}
      {selectedTab === "Cancelled Requests" ? (
        myCancelledRequets.map((request, index) => (
          <div
            key={index}
            className="flex space-x-5 border-b border-gray-200 p-5"
          >
            <HiClipboardDocumentCheck size="20" className="mr-2" />
            <div className="grow">
              <span
                className={
                  request.status === "completed"
                    ? "inline-flex items-center bg-lime-100 text-lime-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                    : request.status === "cancelled"
                    ? "inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                    : "inline-flex items-center bg-yellow-100 text-yellow-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                }
              >
                <span
                  className={
                    request.status === "completed"
                      ? "w-2 h-2 mr-1 bg-lime-500 rounded-full"
                      : request.status === "cancelled"
                      ? "w-2 h-2 mr-1 bg-red-500 rounded-full"
                      : "w-2 h-2 mr-1 bg-yellow-400 rounded-full"
                  }
                ></span>
                {request.status}
              </span>
              <p className="my-1 text-lg">RQ-{request.requestId}</p>
              <p className="font-bold text-base">{request.clientId.fullName}</p>
              <p className="text-gray-500 text-sm">
                Phone Number: {request.clientId.phoneNumber}
              </p>
              <p className="text-gray-500 text-sm">City: {request.city}</p>
              <p className="text-gray-500 text-sm">State: {request.state}</p>
              <p className="text-gray-500 text-sm">
                Address: {request.address}
              </p>
              <p className="text-gray-500 text-sm">
                {request.numberOfBags} Standard Refuse Bags
              </p>
              <p className="text-gray-500 text-sm">
                {moment(new Date(request.dateOfPickup)).format("MMMM Do YYYY")}
              </p>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}

      {/* Footer */}
      {selectedTab === "All Requests" ? (
        <nav className="flex justify-between items-center mx-10 my-5">
          <span className="text-sm font-normal text-gray-500">
            Showing{" "}
            <span className="font-semibold text-gray-900">
              {page * 5 - 4} -{" "}
              {page * 5 > totalRequests ? totalRequests : page * 5}
            </span>{" "}
            of{" "}
            <span className="font-semibold text-gray-900">{totalRequests}</span>
          </span>
          <ul className="inline-flex items-center -space-x-px">
            {page === "1" ? (
              <></>
            ) : (
              <li>
                <Link
                  to={{
                    pathname: "/staff/dashboard",
                    search: `?page=${parseInt(page) - 1}`,
                  }}
                  className="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </li>
            )}
            {parseInt(page) === totalPages ? (
              <></>
            ) : (
              <li>
                <Link
                  to={{
                    pathname: "/staff/dashboard",
                    search: `?page=${parseInt(page) + 1}`,
                  }}
                  className="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </li>
            )}
          </ul>
        </nav>
      ) : (
        <></>
      )}
    </div>
  );
}
