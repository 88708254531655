import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo2.png";
import GooglePlayIcon from "../../assets/images/android.png";
import IOSIcon from "../../assets/images/ios.png";

function Privacy() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy - Trash4Me</title>
      </Helmet>

      {/* Navbar */}
      <nav className=" px-5 md:px-10 pt-2 border-b-2">
        <div className="container flex flex-wrap justify-between items-center mx-auto px-5 md:px-10 pb-4 ">
          <a href="/" className="flex items-center">
            <img src={logo} alt="" width={150} />
          </a>
        </div>
      </nav>

      {/* Main Body */}
      <div className="max-w-7xl mx-auto my-10">
        <p className="text-2xl font-bold">Privacy Policy</p>
        <p className="my-5">
          At Trash4me, we are committed to protecting the privacy and security
          of our customers' personal information. This Privacy Policy outlines
          how we collect, use, disclose, and protect the personal information we
          gather from individuals who use our trash disposal services. Please
          read this Privacy Policy carefully to understand our practices
          regarding your personal information.
        </p>
        <p className="text-xl font-bold">1. Information We Collect</p>
        <p className="text-lg font-bold my-3">1.1 Personal Information</p>
        <p className="">
          We may collect personal information from you when you interact with
          our website, mobile application, or contact our customer support. This
          may include your name, address, email address, phone number, and other
          relevant information necessary for providing our services.
        </p>
        <p className="text-lg font-bold my-3">1.2 Non-Personal Information</p>
        <p className="">
          We may also collect non-personal information such as your IP address,
          browser type, operating system, and usage data when you visit our
          website or use our services. This information is collected for
          analytical purposes and does not directly identify you.
        </p>

        <p className="text-xl font-bold mt-5">2. Use of Information</p>
        <p className="text-lg font-bold my-3">2.1 Providing Services</p>
        <p className="">
          We may use the personal information we collect to provide you with our
          trash disposal services, including scheduling pickups, processing
          payments, and communicating important service-related information.
        </p>
        <p className="text-lg font-bold my-3">2.2 Customer Support</p>
        <p className="">
          We may use your personal information to respond to your inquiries,
          provide customer support, and address any issues or concerns you may
          have.
        </p>
        <p className="text-lg font-bold my-3">
          2.3 Marketing and Communication
        </p>
        <p className="">
          With your consent, we may use your personal information to send you
          promotional offers, newsletters, or other marketing communications
          related to our services. You can opt-out of receiving such
          communications at any time.
        </p>
        <p className="text-lg font-bold my-3">2.4 Legal Compliance</p>
        <p className="">
          We may use and disclose your personal information as required by law
          or if we believe it is necessary to protect our rights, safety, or
          property, as well as to comply with applicable laws, regulations, or
          legal processes.
        </p>

        <p className="text-xl font-bold mt-5">3. Information Sharing</p>
        <p className="">
          We may share your personal information with third parties in the
          following circumstances:
        </p>
        <p className="text-lg font-bold my-3">3.1 Service Providers</p>
        <p className="">
          We may engage third-party service providers to perform certain
          functions on our behalf, such as payment processing, data analysis,
          customer service, or marketing assistance. These service providers
          will have access to your personal information only to the extent
          necessary to perform their functions and are obligated to maintain its
          confidentiality.
        </p>
        <p className="text-lg font-bold my-3">3.2 Legal Requirements</p>
        <p className="">
          We may disclose your personal information if required to do so by law
          or if we believe that such action is necessary to comply with a legal
          obligation, protect our rights or the rights of others, investigate
          fraud, or respond to a government request.
        </p>

        <p className="text-xl font-bold mt-5">4. Data Security</p>
        <p className="">
          We take appropriate security measures to protect your personal
          information from unauthorized access, loss, misuse, or alteration.
          However, no method of transmission over the internet or electronic
          storage is completely secure, and we cannot guarantee its absolute
          security.
        </p>

        <p className="text-xl font-bold mt-5">5. Your Choices</p>
        <p className="text-lg font-bold my-3">5.1 Opt-Out</p>
        <p className="">
          If you no longer wish to receive marketing communications from us, you
          can opt-out by following the instructions provided in the
          communication or by contacting us directly.
        </p>
        <p className="text-lg font-bold my-3">5.2 Access and Correction</p>
        <p className="">
          You may request access to your personal information we hold about you
          and request corrections or updates to ensure its accuracy.
        </p>

        <p className="text-xl font-bold mt-5">6. Children's Privacy</p>
        <p className="">
          Our services are not intended for individuals under the age of 18. We
          do not knowingly collect personal information from children. If you
          are a parent or guardian and believe that your child has provided us
          with personal information, please contact us, and we will take steps
          to delete such information.
        </p>

        <p className="text-xl font-bold mt-5">
          7. Changes to the Privacy Policy
        </p>
        <p className="">
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be effective immediately upon posting the
          revised Privacy Policy on our website. We encourage you to review this
          Privacy Policy periodically to stay informed about how we collect,
          use, and protect your personal information.
        </p>

        <p className="text-xl font-bold mt-5">8. Contact Us</p>
        <p className="">
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or the privacy practices of Trash4me, please contact us
          at support@trash4me.com.
        </p>
        <p className="mt-3 font-bold">
          By using our services or providing your personal information to us,
          you signify your consent to the terms of this Privacy Policy.
        </p>
      </div>

      {/* Footer Section */}
      <div className="w-full px-5 md:px-10 bg-black">
        <div className="mx-auto max-w-7xl text-white flex space-x-5 py-10">
          <div>
            <Link to="/">
              <img src={logo2} alt="" width={150} />
            </Link>
            <p className="mt-3">support@trash4me.com</p>
            <p className="mt-3">+234 (0) 806 442 3806</p>
            <p className="mt-3">© 2022Trash4me™. All Rights Reserved.</p>
            <div className="flex space-x-3">
              <Link to="/privacy">
                <p className="mt-3">Privacy Policy</p>
              </Link>
              <Link to="/terms">
                <p className="mt-3">Terms of service</p>
              </Link>
            </div>
          </div>
          <div className="grow"></div>
          <div className="flex flex-col space-y-3">
            <p>Get the App</p>
            <img
              src={GooglePlayIcon}
              alt="Google Play Store Icon"
              className="w-32 md:w-48 border rounded-lg"
            />
            <img
              src={IOSIcon}
              alt="Apple Store Icon"
              className="w-32 border rounded-lg md:w-48"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
