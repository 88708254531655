import api from "../utils/axios";

const StaffAPI = {
  createStaff: async function (data) {
    let response = await api.request({
      url: `staff`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  updateProfilePicture: async function (file) {
    const formData = new FormData();
    formData.append("image", file);

    let response = await api.request({
      url: `staff/profile-picture`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  getStaffs: async function (data) {
    const perPage = data.perPage || 5;
    const page = data.page || 1;
    const name = data.name;

    const response = await api.request({
      url: `all-staff?perPage=${perPage}&page=${page}&name=${name}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  getStaff: async function (id) {
    const response = await api.request({
      url: `staff/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  getRequests: async function () {
    const response = await api.request({
      url: `staff/requests`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  changePassword: async function (data) {
    let response;
    if (data.otp) {
      response = await api.request({
        url: `staff/verify-otp`,
        method: "POST",
        data: { staffId: data.staffId, otp: data.otp },
        headers: {
          "Content-Type": "application/json",
          auth: localStorage.getItem("token") ?? "",
        },
      });
      localStorage.setItem("token", response.data.token);
    }

    response = await api.request({
      url: `staff/change-password`,
      method: "POST",
      data: {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
      },
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  toggleStaffStatus: async function (data) {
    const response = await api.request({
      url: `staff/active-status`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  assign: async function (data) {
    await api.request({
      url: `staff/accept-request`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });
  },
  updateStatus: async function (data) {
    await api.request({
      url: `staff/update-request-status`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });
  },
  updateStaff: async function (data) {
    const response = await api.request({
      url: `staff/update`,
      method: "PUT",
      data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  deleteStaff: async function (id) {
    console.log(id);
    const response = await api.request({
      url: `staff/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  getNotifications: async function () {
    const response = await api.request({
      url: `/staff/notifications`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
};

export default StaffAPI;
