import api from "../utils/axios";

const SubscriptionAPI = {
  createSubscription: async function (data) {
    const response = await api.request({
      url: `subscription`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  getSubscriptions: async function () {
    const response = await api.request({
      url: `subscriptions`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  updateSubscription: async function (data) {
    const response = await api.request({
      url: `subscription/update`,
      method: "PUT",
      data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  deleteSubscription: async function (id) {
    const response = await api.request({
      url: `subscription/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
};

export default SubscriptionAPI;
