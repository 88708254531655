import React from "react";

export default function PhoneNumberInput({
  id,
  label,
  type,
  placeholder,
  required,
  onChange,
  countryCodes,
  selectedCountryCode,
  handleCountryCodeChange,
  value,
  error,
  errorMessage,
}) {
  return (
    <div className="mt-5">
      <label for={id} className="block mb-2 text-sm font-medium text-gray-900">
        {label}
      </label>
      <div
        className={
          error
            ? "grid grid-cols-3 bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 w-full"
            : "grid grid-cols-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 w-full"
        }
      >
        <select
          className="bg-transparent border-0 rounded-l-lg focus:ring-lime-500"
          value={selectedCountryCode}
          onChange={handleCountryCodeChange}
        >
          {countryCodes.map((code) => (
            <option key={code.value} value={code.value}>
              {code.label}
            </option>
          ))}
        </select>
        <input
          className="col-span-2 bg-transparent border-0 rounded-r-lg focus:ring-lime-500"
          type={type}
          id={id}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          value={value}
        />
      </div>
      {error ? (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          {errorMessage}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
}
