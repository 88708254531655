import React from "react";
import Icons from "../assets/icons";

function NotificationItem({ notification }) {
  return (
    <li>
      <div className="grid grid-cols-6">
        <Icons.NotificationIcon />
        <div className="col-span-5">
          <p className="font-bold text-lg">{notification.title}</p>
          <p>{notification.message}</p>
        </div>
      </div>
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
    </li>
  );
}

export default NotificationItem;
