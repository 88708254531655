import React, { useState } from "react";
import {
  HiBars3,
  HiHome,
  HiArrowLeftOnRectangle,
  HiXMark,
  HiUser,
  HiAdjustmentsHorizontal,
} from "react-icons/hi2";
import logo from "../assets/images/logo.png";
import { useLocation, Link } from "react-router-dom";
import LogoutModal from "../components/modals/LogoutModal";

export default function StaffSidebar({ children }) {
  const [open, setOpen] = useState(true);
  const [showLogout, setShowLogout] = useState(false);
  const location = useLocation();

  return (
    <>
      <div className="flex space-x-3">
        <LogoutModal
          show={showLogout}
          toggle={() => setShowLogout(!showLogout)}
        />
        {open ? (
          <div className="h-screen w-3/12 px-4 m-0 hidden md:flex flex-col bg-gray-100 shadow-lg transition-all duration-300 ease-linear">
            <HeaderIcon isOpen={open} onClick={() => setOpen(!open)} />
            <Divider />
            <SideBarIcon
              icon={<HiHome size="20" className="mr-2" />}
              text="Dashboard"
              active={location.pathname.endsWith("dashboard")}
              path="/staff/dashboard"
              search="?tab=All%20Requests"
            />
            <Divider />
            <SideBarIcon
              icon={<HiUser size="20" className="mr-2" />}
              text="Profile"
              active={location.pathname.endsWith("profile")}
              path="/staff/profile"
            />
            <Divider />
            <SideBarIcon
              icon={<HiAdjustmentsHorizontal size="20" className="mr-2" />}
              text="Settings"
              active={location.pathname.endsWith("settings")}
              path="/staff/settings"
            />
            <Divider />
            <div className="grow"></div>
            <LogoutIcon
              onClick={() => setShowLogout(!showLogout)}
              icon={<HiArrowLeftOnRectangle size="20" className="mr-2" />}
              text="Logout"
            />
          </div>
        ) : (
          <div className="hidden md:flex h-screen w-max px-4 m-0 flex-col bg-gray-100 shadow-lg transition-all duration-300 ease-linear">
            <HeaderIcon isOpen={open} onClick={() => setOpen(!open)} />
            <Divider />
            <SideBarIconMini
              icon={<HiHome size="20" />}
              text="Dashboard"
              active={location.pathname.endsWith("dashboard")}
              path="/staff/dashboard"
              search="?tab=All%20Requests"
            />
            <Divider />
            <SideBarIconMini
              icon={<HiUser size="20" />}
              text="Profile"
              active={location.pathname.endsWith("requests")}
              path="/staff/profile"
            />
            <Divider />
            <SideBarIconMini
              icon={<HiAdjustmentsHorizontal size="20" />}
              text="Settings"
              active={location.pathname.endsWith("settings")}
              path="/staff/settings"
            />
            <Divider />
            <div className="grow"></div>
            <LogoutIconMini
              onClick={() => setShowLogout(!showLogout)}
              icon={<HiArrowLeftOnRectangle size="20" />}
              text="Logout"
            />
          </div>
        )}
        {children}
      </div>
    </>
  );
}

const HeaderIcon = ({ isOpen, onClick }) => (
  <button
    onClick={() => onClick()}
    className="relative flex items-center justify-between h-12 w-full px-4 my-2 text-lime-500 rounded-lg transition-all duration-300 ease-linear cursor-pointer"
  >
    {isOpen ? <img src={logo} width={150} alt="App Logo" /> : <></>}
    {isOpen ? <HiXMark size={30}></HiXMark> : <HiBars3 size="30" />}
  </button>
);

const SideBarIcon = ({ icon, text = "", active, path, search }) => (
  <Link
    to={{ pathname: path, search }}
    className={`${active ? "sidebar-icon-active" : "sidebar-icon"} group`}
  >
    {icon} {text}
  </Link>
);

const LogoutIcon = ({ icon, text = "", active, onClick }) => (
  <button
    onClick={() => onClick()}
    className={`${active ? "logout-icon-active" : "logout-icon"} group`}
  >
    {icon} {text}
  </button>
);

const SideBarIconMini = ({ icon, text = "", active, path, search }) => (
  <Link
    to={{ pathname: path, search }}
    className={`${
      active ? "sidebar-icon-mini-active" : "sidebar-icon-mini"
    } group`}
  >
    {icon}
    <span className="sidebar-tooltip group-hover:scale-100">{text}</span>
  </Link>
);

const LogoutIconMini = ({ icon, text = "", active, onClick }) => (
  <button
    onClick={() => onClick()}
    className={`${
      active ? "logout-icon-mini-active" : "logout-icon-mini"
    } group`}
  >
    {icon}
    <span className="logout-tooltip group-hover:scale-100">{text}</span>
  </button>
);

const Divider = () => <hr className="sidebar-hr" />;
