import React from "react";
import { HiArrowLeftOnRectangle } from "react-icons/hi2";
import OutlineButton from "../OutlineButton";
import DangerButton from "../DangerButton";
import { useNavigate } from "react-router-dom";

export default function LogoutModal({ show, toggle }) {
  const navigate = useNavigate();
  return (
    <div
      className={
        show
          ? "w-full bg-black bg-opacity-20 fixed z-30 inset-0"
          : "hidden w-full bg-black bg-opacity-20 fixed z-30 inset-0"
      }
    >
      <div
        id="staticModal"
        data-modal-backdrop="static"
        tabindex="-1"
        aria-hidden="true"
        className="max-w-xl mt-20 overflow-y-auto overflow-x-hidden fixed z-50 p-4 w-full md:inset-0 h-modal mx-auto md:h-full"
      >
        <div className="relative w-full max-w-xl h-full md:h-auto">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow">
            {/* Modal header */}
            <div className="flex justify-between items-start p-4 rounded-t border-b">
              <HiArrowLeftOnRectangle size="30" className="text-red-500" />
              <button
                onClick={() => toggle()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-red-500 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                data-modal-toggle="staticModal"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="px-6 pt-4">
              <p className="font-bold text-lg">
                Are you sure you want to Logout?
              </p>
              <p className="text-base leading-relaxed text-gray-500">
                You'll be required to provide your login credentials when next
                you want to login.
              </p>
            </div>
            {/* Modal footer */}
            <div className="flex items-center px-6 space-x-2 rounded-b border-gray-200">
              <OutlineButton
                onClick={() => toggle()}
                disabled={false}
                isLoading={false}
                label="Cancel"
              />
              <DangerButton
                onClick={() => {
                  localStorage.removeItem("token");
                  navigate("/login");
                  toggle();
                }}
                disabled={false}
                isLoading={false}
                label="Logout"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
