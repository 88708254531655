import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo2.png";
import GooglePlayIcon from "../../assets/images/android.png";
import IOSIcon from "../../assets/images/ios.png";

function Terms() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy - Trash4Me</title>
      </Helmet>

      {/* Navbar */}
      <nav className=" px-5 md:px-10 pt-2 border-b-2">
        <div className="container flex flex-wrap justify-between items-center mx-auto px-5 md:px-10 pb-4 ">
          <a href="/" className="flex items-center">
            <img src={logo} alt="" width={150} />
          </a>
        </div>
      </nav>

      {/* Main Body */}
      <div className="max-w-7xl mx-auto my-10">
        <p className="text-2xl font-bold">Terms and Conditions</p>
        <p className="my-5">
          These Terms and Conditions ("Agreement") govern your use of the
          services provided by Trash4me, a trash disposal company. By using our
          services, you agree to comply with and be bound by the following terms
          and conditions:
        </p>
        <p className="text-xl font-bold">1. Service Agreement</p>
        <p className="text-lg font-bold my-3">1.1 Scope of Services</p>
        <p className="">
          Trash4me provides trash disposal services to residential and
          commercial customers. Our services include collecting and disposing of
          waste materials in a safe and environmentally responsible manner.
        </p>
        <p className="text-lg font-bold my-3">
          1.2 Compliance with Laws and Regulations
        </p>
        <p className="">
          Customers must comply with all applicable local, state, and federal
          laws and regulations regarding waste disposal. It is the customer's
          responsibility to ensure that the waste materials placed for disposal
          do not violate any laws or regulations.
        </p>
        <p className="text-lg font-bold my-3">1.3 Limitations</p>
        <p className="">
          Trash4me reserves the right to refuse service or restrict the
          acceptance of certain waste materials if they pose a risk to health,
          safety, or the environment. Hazardous, toxic, or prohibited materials
          will not be accepted.
        </p>

        <p className="text-xl font-bold mt-5">2. Scheduling and Collection</p>
        <p className="text-lg font-bold my-3">2.1 Service Availability</p>
        <p className="">
          Trash4me will provide trash collection services according to the
          agreed-upon schedule. However, service availability may vary based on
          location and other factors beyond our control.
        </p>
        <p className="text-lg font-bold my-3">2.2 Access to Waste Materials</p>
        <p className="">
          Customers are responsible for ensuring that waste materials are easily
          accessible for collection. Trash containers should be placed in
          designated pickup areas and not obstructed by vehicles or other
          objects.
        </p>
        <p className="text-lg font-bold my-3">2.3 Collection Guidelines</p>
        <p className="">
          Customers should follow any specific guidelines provided by Trash4me
          for waste sorting, packaging, or container requirements. Failure to
          comply with these guidelines may result in refusal of collection or
          additional charges.
        </p>

        <p className="text-xl font-bold mt-5">3. Payment and Billing</p>
        <p className="text-lg font-bold my-3">3.1 Fees and Charges</p>
        <p className="">
          Customers agree to pay the fees and charges associated with the trash
          disposal services provided by Trash4me. The applicable rates will be
          communicated to the customer at the time of service agreement or as
          otherwise determined by Trash4me.
        </p>
        <p className="text-lg font-bold my-3">3.2 Billing and Payment Terms</p>
        <p className="">
          Billing cycles, payment methods, and due dates will be specified in
          the service agreement or communicated to the customer separately.
          Failure to make timely payments may result in the suspension or
          termination of services.
        </p>

        <p className="text-xl font-bold mt-5">
          4. Liability and Indemnification
        </p>
        <p className="text-lg font-bold my-3">4.1 Limitation of Liability</p>
        <p className="">
          Trash4me shall not be liable for any damages, losses, or expenses
          arising from the use or inability to use our services, including but
          not limited to direct, indirect, incidental, or consequential damages.
        </p>
        <p className="text-lg font-bold my-3">4.2 Indemnification</p>
        <p className="">
          Customers agree to indemnify, defend, and hold Trash4me harmless from
          any claims, liabilities, damages, or expenses arising out of or
          related to their use of our services, including any violations of
          these Terms and Conditions.
        </p>

        <p className="text-xl font-bold mt-5">Intellectual Property</p>
        <p className="">
          All intellectual property rights related to Trash4me's services,
          including but not limited to trademarks, logos, and content, are the
          property of Trash4me. Customers are prohibited from using,
          reproducing, or distributing any intellectual property without the
          explicit consent of Trash4me.
        </p>

        <p className="text-xl font-bold mt-5">6. Termination</p>
        <p className="">
          Either party may terminate the service agreement at any time by
          providing written notice to the other party. Upon termination, any
          outstanding fees or charges shall become immediately due and payable.
        </p>

        <p className="text-xl font-bold mt-5">
          7. Governing Law and Jurisdiction
        </p>
        <p className="">
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of [jurisdiction]. Any disputes arising out
          of or in connection with these Terms and Conditions shall be subject
          to the exclusive jurisdiction of the courts in Nigeria.
        </p>

        <p className="text-xl font-bold mt-5">8. Amendments</p>
        <p className="">
          Trash4me reserves the right to modify or amend these Terms and
          Conditions at any time. Customers will be notified of any changes, and
          continued use of our services after such modifications constitutes
          acceptance of the revised terms.
        </p>

        <p className="text-xl font-bold mt-5">8. Severability</p>
        <p className="">
          If any provision of these Terms and Conditions is found to be invalid
          or unenforceable, the remaining provisions shall continue to be valid
          and enforceable to the fullest extent permitted by law.
        </p>
        <p className="mt-3">
          By using Trash4me's services, you acknowledge that you have read,
          understood, and agreed to these Terms and Conditions. If you do not
          agree with any provision of this Agreement, please refrain from using
          our services.
        </p>
      </div>

      {/* Footer Section */}
      <div className="w-full px-5 md:px-10 bg-black">
        <div className="mx-auto max-w-7xl text-white flex space-x-5 py-10">
          <div>
            <Link to="/">
              <img src={logo2} alt="" width={150} />
            </Link>
            <p className="mt-3">support@trash4me.com</p>
            <p className="mt-3">+234 (0) 806 442 3806</p>
            <p className="mt-3">© 2022Trash4me™. All Rights Reserved.</p>
            <div className="flex space-x-3">
              <Link to="/privacy">
                <p className="mt-3">Privacy Policy</p>
              </Link>
              <Link to="/terms">
                <p className="mt-3">Terms of service</p>
              </Link>
            </div>
          </div>
          <div className="grow"></div>
          <div className="flex flex-col space-y-3">
            <p>Get the App</p>
            <img
              src={GooglePlayIcon}
              alt="Google Play Store Icon"
              className="w-32 md:w-48 border rounded-lg"
            />
            <img
              src={IOSIcon}
              alt="Apple Store Icon"
              className="w-32 border rounded-lg md:w-48"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
