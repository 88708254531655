import React from "react";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

export default function NotFound({ show = true }) {
  return (
    <div className={show ? "" : "w-10/12 h-screen overflow-auto"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 - Trash4Me</title>
      </Helmet>
      {show ? (
        <nav className=" px-5 md:px-10 pt-2 border-b-2">
          <div className="container flex flex-wrap justify-between items-center mx-auto px-5 md:px-10 pb-4 ">
            <a href="/" className="flex items-center">
              <img src={logo} alt="" width={150} />
            </a>
          </div>
        </nav>
      ) : (
        <></>
      )}
      <div className="bg-white">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-lime-600">
              404
            </h1>
            <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
              Something's missing.
            </p>
            <p className="mb-4 text-lg font-light text-gray-500">
              Sorry, we can't find that page.{" "}
              {show ? "You'll find lots to explore on the home page." : ""}
            </p>
            {show ? (
              <Link
                to="/"
                className="inline-flex text-white bg-lime-600 hover:bg-lime-800 focus:ring-4 focus:outline-none focus:ring-lime-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
              >
                Back to Homepage
              </Link>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
