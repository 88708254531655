import React, { useState } from "react";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormInput from "../FormInput";
import PhoneNumberInput from "../PhoneNumberInput";
import Button from "../../components/Button";
import StaffAPI from "../../apis/staffAPI";
import { useNavigate } from "react-router-dom";

const countryCodes = [
  { label: "+234", value: "+234" },
  // Add more country codes as needed
];

export default function AddStaffModal({ show, toggle }) {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+234");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const validateFields = () => {
    if (firstName.length < 2) {
      setFirstNameError(true);
      return;
    }

    if (lastName.length < 2) {
      setLastNameError(true);
      return;
    }

    if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setEmailError(true);
      return;
    }

    if (phoneNumber.length !== 10) {
      setPhoneNumberError(true);
      return;
    }

    createStaff();
  };

  const createStaff = async () => {
    setLoading(true);
    let data = {
      firstName,
      lastName,
      email,
      phoneNumber: `+234${phoneNumber}`,
    };

    try {
      let response = await StaffAPI.createStaff(data);
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      Toast.success(response.message);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={
        show
          ? "w-full bg-black bg-opacity-20 fixed z-30 inset-0"
          : "hidden w-full bg-black bg-opacity-20 fixed z-30 inset-0"
      }
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        id="staticModal"
        data-modal-backdrop="static"
        tabindex="-1"
        aria-hidden="true"
        className="max-w-2xl mt-20 overflow-y-auto overflow-x-hidden fixed z-50 p-4 w-full md:inset-0 h-modal mx-auto md:h-full"
      >
        <div className="relative w-full max-w-2xl h-full md:h-auto">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow">
            {/* Modal header */}
            <div className="flex items-start p-4 rounded-t">
              <button
                onClick={() => toggle()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-red-500 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                data-modal-toggle="staticModal"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="px-6 py-4">
              <div className="md:grid grid-cols-2 gap-x-5 gap-y-2">
                <FormInput
                  id="first_name"
                  label="First Name"
                  placeholder="John"
                  required={true}
                  type="text"
                  value={firstName}
                  error={firstNameError}
                  errorMessage="Please enter your first name"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setFirstNameError(false);
                  }}
                />
                <FormInput
                  id="last_name"
                  label="Last Name"
                  placeholder="Doe"
                  required={true}
                  type="text"
                  value={lastName}
                  error={lastNameError}
                  errorMessage="Please enter your last name"
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setLastNameError(false);
                  }}
                />
                <FormInput
                  id="email"
                  label="Email"
                  placeholder="abc@xyz.com"
                  required={true}
                  type="email"
                  value={email}
                  error={emailError}
                  errorMessage="Please enter a valid email address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                  }}
                />
                <PhoneNumberInput
                  id="phone_number"
                  label="Phone Number"
                  placeholder="8100000000"
                  required={true}
                  type="text"
                  value={phoneNumber}
                  error={phoneNumberError}
                  errorMessage="Please enter your phone number"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    setPhoneNumberError(false);
                  }}
                  countryCodes={countryCodes}
                  selectedCountryCode={selectedCountryCode}
                  handleCountryCodeChange={(e) =>
                    setSelectedCountryCode(e.target.value)
                  }
                />
              </div>
              <Button
                onClick={validateFields}
                disabled={false}
                isLoading={loading}
                label="Add Staff"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
