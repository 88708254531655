import React from "react";

export default function FormInput({
  id,
  label,
  type,
  placeholder,
  required,
  onChange,
  value,
  error,
  errorMessage,
}) {
  return (
    <div className="mt-5">
      <label for={id} className="block mb-2 text-sm font-medium text-gray-900">
        {label}
      </label>
      <input
        type={type}
        id={id}
        className={
          error
            ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500"
            : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5"
        }
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
      />
      {error ? (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          {errorMessage}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
}
