import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Privacy from "./pages/privacy/Privacy";
import Terms from "./pages/terms/Terms";
import ForgotPassword from "./pages/forgot_password/ForgotPassword";
import ResetPassword from "./pages/reset_password/ResetPassword";
import AdminDashboard from "./pages/admin/AdminDashboard";
import SetPassword from "./pages/set_password/SetPassword";
import PaymentHistory from "./pages/admin/PaymentHistory";
import PromotionalMessages from "./pages/admin/PromotionalMessages";
import Settings from "./pages/admin/Settings";
import Staff from "./pages/admin/Staff";
import Subcriptions from "./pages/admin/Subcriptions";
import SingleSubscription from "./pages/admin/SingleSubscription";
import UserSubscriptions from "./pages/admin/UserSubscriptions";
import Users from "./pages/admin/Users";
import NotFound from "./pages/404/404";
import Requests from "./pages/admin/Requests";
import AdminLayout from "./layouts/AdminLayout";
import StaffLayout from "./layouts/StaffLayout";
import reportWebVitals from "./reportWebVitals";
import "flowbite";
import { Provider } from "react-redux";
import { persistor, store } from "./app/store";
import { PersistGate } from "redux-persist/integration/react";

// Staff imports
import StaffDashboard from "./pages/staff/StaffDashboard";
import StaffSettings from "./pages/staff/StaffSettings";
import StaffProfile from "./pages/staff/StaffProfile";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            {/* <Route path="about" element={<About />} />
            <Route path="contact-us" element={<ContactUs />} /> */}
            <Route path="login" element={<Login />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="terms" element={<Terms />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="set-password" element={<SetPassword />} />
            <Route path="admin/" element={<AdminLayout />}>
              <Route path="dashboard" element={<AdminDashboard />} />
              {/* <Route path="messages" element={<Messages />} /> */}
              <Route
                path="user-subscriptions"
                element={<UserSubscriptions />}
              />
              <Route path="payment-history" element={<PaymentHistory />} />
              <Route path="promotional" element={<PromotionalMessages />} />
              <Route path="settings" element={<Settings />} />
              <Route path="staff" element={<Staff />} />
              <Route path="subcriptions" element={<Subcriptions />} />
              <Route path="subcription" element={<SingleSubscription />} />
              <Route path="users" element={<Users />} />
              <Route path="requests" element={<Requests />} />
              <Route path="" element={<Navigate to="/404" replace />} />
            </Route>
            <Route path="staff/" element={<StaffLayout />}>
              <Route path="dashboard" element={<StaffDashboard />} />
              <Route path="settings" element={<StaffSettings />} />
              <Route path="profile" element={<StaffProfile />} />
              <Route path="" element={<Navigate to="/404" replace />} />
            </Route>

            {/* Not Found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
