import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserAPI from "../../apis/userAPI";
import { Helmet } from "react-helmet";
import {
  HiHome,
  HiChatBubbleLeftEllipsis,
  HiEnvelope,
  HiUser,
  HiCreditCard,
  HiBanknotes,
  HiAdjustmentsHorizontal,
  HiArrowLeftOnRectangle,
  HiClipboardDocumentCheck,
  HiUserGroup,
} from "react-icons/hi2";
import { Spinner } from "loading-animations-react";
import moment from "moment";
import LogoutModal from "../../components/modals/LogoutModal";
import NotificationSideBar from "../../components/NotificationSideBar";

export default function UserSubscriptions() {
  let navigate = useNavigate();
  let [searchParams, _] = useSearchParams();
  const page = searchParams.get("page");
  const [loading, setLoading] = useState(true);
  const [showLogout, setShowLogout] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [userSubs, setUserSubs] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [totalSubs, setTotalSubs] = useState(null);

  const fetchSubs = async (perPage, page) => {
    setLoading(true);

    try {
      let response = await UserAPI.getUserSubs({ perPage, page });
      setUserSubs(response.data);
      setTotalPages(response.totalPages);
      setTotalSubs(response.totalResults);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubs(5, page);
  }, [page]);

  return (
    <div className="w-full md:w-10/12 h-screen overflow-auto">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin - Trash4Me</title>
      </Helmet>
      <LogoutModal
        show={showLogout}
        toggle={() => setShowLogout(!showLogout)}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* Notification SideBar */}
      <NotificationSideBar
        show={showNotification}
        toggle={() => setShowNotification(!showNotification)}
      />

      <div className="flex items-center space-x-3 border-b py-5">
        <HiClipboardDocumentCheck size="30" className="text-lime-500" />
        <p className="font-bold text-lg">User Subscriptions</p>
        <div className="grow"></div>
        <button
          type="button"
          onClick={() => {
            document.querySelector("#mobile-menu").classList.toggle("hidden");
          }}
          className="inline-flex items-center py-2 md:px-5 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          onClick={() => setShowNotification(!showNotification)}
          className="inline-flex items-center py-2 pr-5 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-7 h-7"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.7029 24H8.29707C5.85922 24 4.46766 21.5478 5.93036 19.8295C6.5779 19.0687 6.97696 18.1665 7.08435 17.2203L7.6806 11.9669C8.00211 9.13414 10.1169 6.80955 12.9734 5.83151V5.69322C12.9734 4.02168 14.3284 2.66663 16 2.66663C17.6715 2.66663 19.0266 4.02168 19.0266 5.69322V5.83151C21.8831 6.80955 23.9979 9.13414 24.3194 11.9669L24.9156 17.2203C25.023 18.1665 25.4221 19.0687 26.0696 19.8295C27.5323 21.5478 26.1407 24 23.7029 24ZM19.9628 26.762C19.353 28.2655 17.8087 29.3333 16 29.3333C14.1913 29.3333 12.647 28.2655 12.0372 26.762C12.0117 26.6993 12 26.632 12 26.5643C12 26.2526 12.2527 26 12.5644 26H19.4356C19.7473 26 20 26.2526 20 26.5643C20 26.632 19.9882 26.6993 19.9628 26.762Z"
              fill="#28303F"
            />
          </svg>
        </button>
        <div
          className="absolute z-10 top-16 right-3 bg-gray-100 w-max hidden md:w-auto"
          id="mobile-menu"
        >
          <ul className="flex flex-col p-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-lg md:font-medium text-lime">
            <li>
              <Link
                to={{
                  pathname: "/admin/dashboard",
                }}
                className="group sidebar-icon"
              >
                <HiHome size="20" className="mr-2" />
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/users", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiUser size="20" className="mr-2" />
                Users
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/staff", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiUserGroup size="20" className="mr-2" />
                Staff
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/requests", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiClipboardDocumentCheck size="20" className="mr-2" />
                Requests
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/admin/user-subscriptions",
                  search: "?page=1",
                }}
                className="group sidebar-icon"
              >
                <HiChatBubbleLeftEllipsis size="20" className="mr-2" />
                User Subscriptions
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/subcriptions" }}
                className="group sidebar-icon"
              >
                <HiCreditCard size="20" className="mr-2" />
                Subcriptions
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/payment-history", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiBanknotes size="20" className="mr-2" />
                Payment History
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/promotional" }}
                className="group sidebar-icon"
              >
                <HiEnvelope size="20" className="mr-2" />
                Promotional Messages
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/settings" }}
                className="group sidebar-icon"
              >
                <HiAdjustmentsHorizontal size="20" className="mr-2" />
                Settings
              </Link>
            </li>
            <li>
              <button
                onClick={() => setShowLogout(!showLogout)}
                className="logout-icon group"
              >
                <HiArrowLeftOnRectangle size="20" className="mr-2" />
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* User Subscriptions Table */}
      {loading ? (
        <Spinner className="w-8 h-8 m-5" text="" />
      ) : (
        <div className="relative overflow-x-auto shadow-md md:rounded-lg mr-5 md:mr-10 my-3">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  User
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  Address
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  Number of Bags
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  Subscription
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  Status
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  Start Date
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  Time of Pickup
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  End Date
                </th>
              </tr>
            </thead>
            <tbody>
              {userSubs.map((element, index) => (
                <tr className="bg-white border-b hover:bg-gray-200">
                  <td className="py-4 px-6 text-center text-base font-semibold whitespace-nowrap">
                    {element.clientId.fullName}
                  </td>
                  <td className="py-4 px-6 text-center whitespace-nowrap">
                    {element.address}
                  </td>
                  <td className="py-4 px-6 text-center whitespace-nowrap">
                    {element.numberOfBags}
                  </td>
                  <td className="py-4 px-6 text-center whitespace-nowrap">
                    {element.subscriptionId.name}
                  </td>
                  <td className="py-4 px-6 text-center whitespace-nowrap">
                    {element.status}
                  </td>
                  <td className="py-4 px-6 text-center whitespace-nowrap">
                    {moment(new Date(element.startDate)).format("MMMM Do YYYY")}
                  </td>
                  <td className="py-4 px-6 text-center whitespace-nowrap">
                    {element.daysOfPickup.join(", ")}
                  </td>
                  <td className="py-4 px-6text-center whitespace-nowrap">
                    {moment(new Date(element.expiryDate)).format(
                      "MMMM Do YYYY"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Footer */}
      {loading ? (
        <></>
      ) : (
        <nav className="flex justify-between items-center mx-10">
          <span className="text-sm font-normal text-gray-500">
            Showing{" "}
            <span className="font-semibold text-gray-900">
              {page * 5 - 4} - {page * 5 > totalSubs ? totalSubs : page * 5}
            </span>{" "}
            of <span className="font-semibold text-gray-900">{totalSubs}</span>
          </span>
          <ul className="inline-flex items-center -space-x-px">
            {page === "1" ? (
              <></>
            ) : (
              <li>
                <Link
                  to={{
                    pathname: "/admin/user-subscriptions",
                    search: `?page=${parseInt(page) - 1}`,
                  }}
                  className="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </li>
            )}
            {parseInt(page) === totalPages ? (
              <></>
            ) : (
              <li>
                <Link
                  to={{
                    pathname: "/admin/users",
                    search: `?page=${parseInt(page) + 1}`,
                  }}
                  className="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  );
}
