import React, { useState, useEffect } from "react";
import {
  HiHome,
  HiChatBubbleLeftEllipsis,
  HiEnvelope,
  HiUser,
  HiCreditCard,
  HiBanknotes,
  HiAdjustmentsHorizontal,
  HiArrowLeftOnRectangle,
  HiClipboardDocumentCheck,
  HiUserGroup,
} from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import { Chart } from "react-google-charts";
import { Spinner } from "loading-animations-react";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import AdminAPI from "../../apis/adminAPI";
import LogoutModal from "../../components/modals/LogoutModal";
import NotificationSideBar from "../../components/NotificationSideBar";

export const options = {
  title: "Users",
};

export default function AdminDashboard() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showLogout, setShowLogout] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [normalUsers, setNormalUsers] = useState(0);
  const [premiumUsers, setPremiumUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalMonthlyEarnings, setTotalMonthlyEarnings] = useState(0);
  const [totalMonthlyRequests, setTotalMonthlyRequests] = useState(0);
  const [upcomingRequests, setUpcomingRequests] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const data = [
    ["Users", "Total Users"],
    ["Normal Users", normalUsers / totalUsers],
    ["Premium Users", premiumUsers / totalUsers],
  ];

  const fetchSummary = async () => {
    setLoading(true);

    try {
      let response = await AdminAPI.getSummary();
      setNormalUsers(response.totalNormalUsers);
      setPremiumUsers(response.totalPremiumUsers);
      setTotalUsers(response.totalUsers);
      setTotalMonthlyEarnings(response.totalMonthlyEarnings);
      setTotalMonthlyRequests(response.totalMonthlyRequests);
      setUpcomingRequests(response.upcomingRequests);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  const getNotifications = async () => {
    let response = await AdminAPI.getNotifications();

    setNotifications(response.data);
  };

  useEffect(() => {
    fetchSummary();
    getNotifications();
  }, []);

  return (
    <div className="w-full md:w-10/12 h-screen overflow-auto">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin - Trash4Me</title>
      </Helmet>
      <LogoutModal
        show={showLogout}
        toggle={() => setShowLogout(!showLogout)}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* Notification SideBar */}
      <NotificationSideBar
        show={showNotification}
        toggle={() => setShowNotification(!showNotification)}
      />

      <div className="flex items-center space-x-3 border-b py-5">
        <HiHome size="30" className="text-lime-500" />
        <p className="font-bold text-lg">Home</p>
        <div className="grow"></div>
        <button
          type="button"
          onClick={() => {
            document.querySelector("#mobile-menu").classList.toggle("hidden");
          }}
          className="inline-flex items-center py-2 md:px-5 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          onClick={() => setShowNotification(!showNotification)}
          className="relative inline-flex items-center py-2 pr-5 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-7 h-7"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.7029 24H8.29707C5.85922 24 4.46766 21.5478 5.93036 19.8295C6.5779 19.0687 6.97696 18.1665 7.08435 17.2203L7.6806 11.9669C8.00211 9.13414 10.1169 6.80955 12.9734 5.83151V5.69322C12.9734 4.02168 14.3284 2.66663 16 2.66663C17.6715 2.66663 19.0266 4.02168 19.0266 5.69322V5.83151C21.8831 6.80955 23.9979 9.13414 24.3194 11.9669L24.9156 17.2203C25.023 18.1665 25.4221 19.0687 26.0696 19.8295C27.5323 21.5478 26.1407 24 23.7029 24ZM19.9628 26.762C19.353 28.2655 17.8087 29.3333 16 29.3333C14.1913 29.3333 12.647 28.2655 12.0372 26.762C12.0117 26.6993 12 26.632 12 26.5643C12 26.2526 12.2527 26 12.5644 26H19.4356C19.7473 26 20 26.2526 20 26.5643C20 26.632 19.9882 26.6993 19.9628 26.762Z"
              fill="#28303F"
            />
          </svg>
          {/* {notifications.length != 0 ? <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-0 dark:border-gray-900">
            {notifications.length}
          </div> : <span></span>} */}
        </button>
        <div
          className="absolute z-10 top-16 right-3 bg-gray-100 w-max hidden md:w-auto"
          id="mobile-menu"
        >
          <ul className="flex flex-col p-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-lg md:font-medium text-lime">
            <li>
              <Link
                to={{
                  pathname: "/admin/dashboard",
                }}
                className="group sidebar-icon"
              >
                <HiHome size="20" className="mr-2" />
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/users", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiUser size="20" className="mr-2" />
                Users
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/staff", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiUserGroup size="20" className="mr-2" />
                Staff
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/admin/requests",
                  search: "?page=1&status=All",
                }}
                className="group sidebar-icon"
              >
                <HiClipboardDocumentCheck size="20" className="mr-2" />
                Requests
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/admin/user-subscriptions",
                  search: "?page=1",
                }}
                className="group sidebar-icon"
              >
                <HiChatBubbleLeftEllipsis size="20" className="mr-2" />
                User Subscriptions
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/subcriptions" }}
                className="group sidebar-icon"
              >
                <HiCreditCard size="20" className="mr-2" />
                Subcriptions
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/payment-history", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiBanknotes size="20" className="mr-2" />
                Payment History
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/promotional" }}
                className="group sidebar-icon"
              >
                <HiEnvelope size="20" className="mr-2" />
                Promotional Messages
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/settings" }}
                className="group sidebar-icon"
              >
                <HiAdjustmentsHorizontal size="20" className="mr-2" />
                Settings
              </Link>
            </li>
            <li>
              <button
                onClick={() => setShowLogout(!showLogout)}
                className="logout-icon group"
              >
                <HiArrowLeftOnRectangle size="20" className="mr-2" />
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/*  */}
      {loading ? (
        <div></div>
      ) : (
        <div className="block md:flex mx-10 my-5">
          <Chart chartType="PieChart" data={data} options={options} />
          <div className="grow md:grid grid-cols-2 gap-5 items-center">
            <div className="h-max mb-5 md:mb-0 p-3 bg-white border border-gray-200 shadow rounded-xl flex space-x-5 items-center">
              <HiBanknotes size="20" className="text-gray-600" />
              <div>
                <p className="text-gray-600">Total Monthly Earnings</p>
                <p className="font-bold">NGN{totalMonthlyEarnings}</p>
              </div>
            </div>
            <div className="h-max p-3 bg-white border border-gray-200 shadow rounded-xl flex space-x-5 items-center">
              <HiBanknotes size="20" className="text-gray-600" />
              <div>
                <p className="text-gray-600">Total Monthly Pickups</p>
                <p className="font-bold">{totalMonthlyRequests}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Upcoming pickups */}
      {loading ? (
        <div></div>
      ) : (
        <div className="flex mr-10 items-center">
          <p className="font-bold text-md my-5">Upcoming Pickups</p>
        </div>
      )}
      {loading ? (
        <Spinner className="w-8 h-8 m-5 " text="" />
      ) : upcomingRequests.length === 0 ? (
        <p className="text-base font-medium">No Upcoming Requests</p>
      ) : (
        <div className="overflow-x-auto relative shadow-md md:rounded-lg mr-10">
          <table className="w-full table-auto text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  ID
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  User
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  Address
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  Status
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {upcomingRequests.map((request, index) => (
                <tr key={index} className="bg-white border-b hover:bg-gray-50">
                  <th
                    scope="row"
                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {`RQ-${request.requestId}`}
                  </th>
                  <td className="py-4 px-6 whitespace-nowrap">
                    {request.clientId.fullName}
                  </td>
                  <td className="py-4 px-6 whitespace-nowrap">
                    {request.clientId.address.fullAddress}
                  </td>
                  <td className="py-4 px-6 flex items-center whitespace-nowrap">
                    <p
                      className={`px-4 rounded-xl ${
                        request.status !== "completed" &&
                        request.status !== "cancelled"
                          ? "bg-yellow-200 text-yellow-500"
                          : request.status === "completed"
                          ? "bg-lime-200 text-lime-500"
                          : "bg-gray-200 text-gray-500"
                      }`}
                    >
                      {request.status}
                    </p>
                  </td>
                  <td className="py-4 px-6 whitespace-nowrap">
                    {moment(new Date(request.dateOfPickup)).format(
                      "MMMM Do YYYY"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
