import React, { useState, useEffect } from "react";
import AdminAPI from "../../apis/adminAPI";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import {
  HiHome,
  HiChatBubbleLeftEllipsis,
  HiEnvelope,
  HiUser,
  HiCreditCard,
  HiBanknotes,
  HiAdjustmentsHorizontal,
  HiArrowLeftOnRectangle,
  HiClipboardDocumentCheck,
  HiUserGroup,
} from "react-icons/hi2";
import PasswordField from "../../components/PasswordField";
import Button from "../../components/Button";
import LogoutModal from "../../components/modals/LogoutModal";
import NotificationSideBar from "../../components/NotificationSideBar";
import FormInput from "../../components/FormInput";

export default function Settings() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pricePerKM, setPricePerKM] = useState("");
  const [pricePerQty, setPricePerQty] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [pricePerKmError, setPricePerkmError] = useState(false);
  const [pricePerQtyError, setPricePerQtyError] = useState(false);
  const [showLogout, setShowLogout] = useState(false);

  const getRequestSettings = async () => {
    try {
      let response = await AdminAPI.getRequestSettings();

      if (response.data.length > 0) {
        setPricePerKM(response.data[0].pricePerKM);
        setPricePerQty(response.data[0].pricePerQty);
      }
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    }
  };

  const updateRequestSettings = async (e) => {
    e.preventDefault();

    if (pricePerKM.length < 1) {
      setPricePerKM(true);
      return;
    }

    if (pricePerQty.length < 1) {
      setPricePerQty(true);
      return;
    }

    setRequestLoading(true);
    let data = {
      pricePerKM,
      pricePerQty,
    };

    try {
      await AdminAPI.updateRequestSettings(data);
      Toast.success("Request prices updated");
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setRequestLoading(false);
    }
  };

  const validateFields = (e) => {
    e.preventDefault();

    if (oldPassword.length < 1) {
      setOldPasswordError(true);
      return;
    }

    if (
      !password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
      )
    ) {
      setPasswordError(true);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      return;
    }

    changePassword();
  };

  const changePassword = async () => {
    setLoading(true);
    let data = {
      oldPassword: oldPassword,
      newPassword: password,
      confirmPassword: confirmPassword,
    };

    try {
      let response = await AdminAPI.changePassword(data);
      console.log(response);
      Toast.success(response.message);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRequestSettings();
  }, []);

  return (
    <div className="w-full md:w-10/12 h-screen overflow-auto">
      <LogoutModal
        show={showLogout}
        toggle={() => setShowLogout(!showLogout)}
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin - Trash4Me</title>
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* Notification SideBar */}
      <NotificationSideBar
        show={showNotification}
        toggle={() => setShowNotification(!showNotification)}
      />

      <div className="flex items-center space-x-3 border-b py-5">
        <HiAdjustmentsHorizontal size="30" className="text-lime-500" />
        <p className="font-bold text-lg">Settings</p>
        <div className="grow"></div>
        <button
          type="button"
          onClick={() => {
            document.querySelector("#mobile-menu").classList.toggle("hidden");
          }}
          className="inline-flex items-center py-2 md:px-5 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          onClick={() => setShowNotification(!showNotification)}
          className="inline-flex items-center py-2 pr-5 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <svg
            className="w-7 h-7"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.7029 24H8.29707C5.85922 24 4.46766 21.5478 5.93036 19.8295C6.5779 19.0687 6.97696 18.1665 7.08435 17.2203L7.6806 11.9669C8.00211 9.13414 10.1169 6.80955 12.9734 5.83151V5.69322C12.9734 4.02168 14.3284 2.66663 16 2.66663C17.6715 2.66663 19.0266 4.02168 19.0266 5.69322V5.83151C21.8831 6.80955 23.9979 9.13414 24.3194 11.9669L24.9156 17.2203C25.023 18.1665 25.4221 19.0687 26.0696 19.8295C27.5323 21.5478 26.1407 24 23.7029 24ZM19.9628 26.762C19.353 28.2655 17.8087 29.3333 16 29.3333C14.1913 29.3333 12.647 28.2655 12.0372 26.762C12.0117 26.6993 12 26.632 12 26.5643C12 26.2526 12.2527 26 12.5644 26H19.4356C19.7473 26 20 26.2526 20 26.5643C20 26.632 19.9882 26.6993 19.9628 26.762Z"
              fill="#28303F"
            />
          </svg>
        </button>
        <div
          className="absolute z-10 top-16 right-3 bg-gray-100 w-max hidden md:w-auto"
          id="mobile-menu"
        >
          <ul className="flex flex-col p-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-lg md:font-medium text-lime">
            <li>
              <Link
                to={{
                  pathname: "/admin/dashboard",
                }}
                className="group sidebar-icon"
              >
                <HiHome size="20" className="mr-2" />
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/users", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiUser size="20" className="mr-2" />
                Users
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/staff", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiUserGroup size="20" className="mr-2" />
                Staff
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/requests", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiClipboardDocumentCheck size="20" className="mr-2" />
                Requests
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/admin/user-subscriptions",
                  search: "?page=1",
                }}
                className="group sidebar-icon"
              >
                <HiChatBubbleLeftEllipsis size="20" className="mr-2" />
                User Subscriptions
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/subcriptions" }}
                className="group sidebar-icon"
              >
                <HiCreditCard size="20" className="mr-2" />
                Subcriptions
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/payment-history", search: "?page=1" }}
                className="group sidebar-icon"
              >
                <HiBanknotes size="20" className="mr-2" />
                Payment History
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/promotional" }}
                className="group sidebar-icon"
              >
                <HiEnvelope size="20" className="mr-2" />
                Promotional Messages
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/admin/settings" }}
                className="group sidebar-icon"
              >
                <HiAdjustmentsHorizontal size="20" className="mr-2" />
                Settings
              </Link>
            </li>
            <li>
              <button
                onClick={() => setShowLogout(!showLogout)}
                className="logout-icon group"
              >
                <HiArrowLeftOnRectangle size="20" className="mr-2" />
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* Main Content */}
      <div className="py-5">
        <p className="font-medium text-lg">Update Request Price</p>
        <p className="text-base leading-relaxed text-gray-500">
          Update the instant request price here.
        </p>
        <hr className="my-3" />
        <div className="md:w-1/2 mx-auto col-span-2 pr-10">
          <form className="">
            <FormInput
              id="price_per_km"
              label="Price per KM"
              placeholder="Price per KM"
              required={true}
              type="number"
              value={pricePerKM}
              error={pricePerKmError}
              errorMessage="Please enter the price per KM"
              onChange={(e) => {
                setPricePerKM(e.target.value);
                setPricePerkmError(false);
              }}
            />
            <FormInput
              id="price_per_qty"
              label="Price per Quantity"
              placeholder="Price per Quantity"
              required={true}
              type="number"
              value={pricePerQty}
              error={pricePerQtyError}
              errorMessage="Please enter the price per KM"
              onChange={(e) => {
                setPricePerQty(e.target.value);
                setPricePerQtyError(false);
              }}
            />
          </form>
          <Button
            onClick={updateRequestSettings}
            disabled={false}
            isLoading={requestLoading}
            label="Update"
          />
        </div>
      </div>
      <div className="py-5">
        <p className="font-medium text-lg">Update password</p>
        <p className="text-base leading-relaxed text-gray-500">
          Update your password settings here.
        </p>
        <hr className="my-3" />
        <div className="md:grid grid-cols-3 space-x-5">
          <div className="mt-5">
            <p className="text-base leading-relaxed text-gray-500">
              Please enter your current password to change your password.
            </p>
          </div>
          <div className="col-span-2 pr-10">
            <form className="">
              <PasswordField
                id="current_password"
                label="Current password"
                required={true}
                value={oldPassword}
                error={oldPasswordError}
                errorMessage="Please enter your current password"
                onChange={(e) => {
                  setOldPassword(e.target.value);
                  setOldPasswordError(false);
                }}
              />
              <p className="text-base leading-relaxed text-gray-500">
                Password must contain at least 1 lowercase, 1 uppercase, 1
                numeric, one special character and must be eight characters or
                longer
              </p>
              <PasswordField
                id="new_password"
                label="New password"
                required={true}
                value={password}
                error={passwordError}
                errorMessage="Password must contain at least 1 lowercase, 1 uppercase, 1 numeric, one special character and must be eight characters or longer"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError(false);
                }}
              />
              <PasswordField
                id="confirm_password"
                label="Confirm password"
                required={true}
                value={confirmPassword}
                error={confirmPasswordError}
                errorMessage="Password must contain at least 1 lowercase, 1 uppercase, 1 numeric, one special character and must be eight characters or longer"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setConfirmPasswordError(false);
                }}
              />
            </form>
            <Button
              onClick={validateFields}
              disabled={false}
              isLoading={loading}
              label="Update Password"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
