import api from "../utils/axios";

const UserAPI = {
  getUsers: async function (data) {
    const perPage = data.perPage || 5;
    const page = data.page || 1;
    const name = data.name;

    const response = await api.request({
      url: `clients?perPage=${perPage}&page=${page}&name=${name}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  getUser: async function (id) {
    const response = await api.request({
      url: `client/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  getUserSubs: async function (data) {
    const perPage = data.perPage || 5;
    const page = data.page || 1;
    const response = await api.request({
      url: `client-subscriptions?perPage=${perPage}&page=${page}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  deleteUser: async function (id) {
    const response = await api.request({
      url: `client/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
};

export default UserAPI;
