import React from "react";
import { Outlet } from "react-router-dom";
import StaffSidebar from "../components/StaffSidebar.jsx";

export default function StaffLayout() {
  return (
    <div>
      <StaffSidebar>
        <Outlet />
      </StaffSidebar>
    </div>
  );
}
