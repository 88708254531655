import api from "../utils/axios";

const AppAPI = {
  contactUs: async function (data) {
    const response = await api.request({
      url: `contact-us`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    localStorage.setItem("token", response.data.token);

    return response.data;
  },
  login: async function (data) {
    const response = await api.request({
      url: `login`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });
    localStorage.setItem("token", response.data.token);

    return response.data;
  },
  forgotPassword: async function (data) {
    const response = await api.request({
      url: `send-reset-password`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  resetPassword: async function (data) {
    const response = await api.request({
      url: `reset-password`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  getStates: async function () {
    const response = await api.request({
      url: `states/63cbc4248d9e1d436c6d8408`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data.data;
  },
};

export default AppAPI;
