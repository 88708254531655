import api from "../utils/axios";

const AdminAPI = {
  getPayments: async function (data) {
    const perPage = data.perPage || 5;
    const page = data.page || 1;
    const response = await api.request({
      url: `/payments?perPage=${perPage}&page=${page}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    // returning the product returned by the API
    return response.data;
  },
  getSummary: async function () {
    const response = await api.request({
      url: `/admin/summary`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    // returning the product returned by the API
    return response.data;
  },
  login: async function (data) {
    const response = await api.request({
      url: `admin/login`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  changePassword: async function (data) {
    let response;
    if (data.otp) {
      response = await api.request({
        url: `admin/verify-otp`,
        method: "POST",
        data: { adminId: data.adminId, otp: data.otp },
        headers: {
          "Content-Type": "application/json",
          auth: localStorage.getItem("token") ?? "",
        },
      });
      localStorage.setItem("token", response.data.token);
    }

    response = await api.request({
      url: `admin/change-password`,
      method: "POST",
      data: {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
      },
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  sendMessage: async function (data) {
    let response = await api.request({
      url: `admin/send-message`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  assignRequestToStaff: async function (data) {
    let response = await api.request({
      url: `admin/request/assign-to-staff`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  getNotifications: async function () {
    const response = await api.request({
      url: `/admin/notifications`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  getRequestSettings: async function () {
    const response = await api.request({
      url: `/request-setting`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
  updateRequestSettings: async function (data) {
    const response = await api.request({
      url: `/update-request-setting`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        auth: localStorage.getItem("token") ?? "",
      },
    });

    return response.data;
  },
};

export default AdminAPI;
