import React, { useState } from "react";
import AdminAPI from "../../apis/adminAPI";
import StaffAPI from "../../apis/staffAPI";
import Toast from "../../utils/toast";
import OtpField from "react-otp-field";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/logo.png";
import Button from "../../components/Button";
import PasswordField from "../../components/PasswordField";
import { useNavigate, useLocation } from "react-router-dom";

export default function SetPassword() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { user, oldPassword } = state || {};
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const validateFields = (e) => {
    e.preventDefault();

    if (otp.length != 4) {
      setOtpError(true);
      return;
    }

    if (
      !password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
      )
    ) {
      setPasswordError(true);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      return;
    }

    resetPassword();
  };

  const resetPassword = async () => {
    setLoading(true);
    let data =
      user.role === "admin"
        ? {
            adminId: user._id,
            otp,
            oldPassword: oldPassword,
            newPassword: password,
            confirmPassword: confirmPassword,
          }
        : {
            staffId: user._id,
            otp,
            oldPassword: oldPassword,
            newPassword: password,
            confirmPassword: confirmPassword,
          };

    try {
      if (user.role === "admin") {
        await AdminAPI.changePassword(data);
        navigate("/admin/dashboard");
      } else {
        await StaffAPI.changePassword(data);
        navigate("/staff/dashboard");
      }
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Set Password - Trash4Me</title>
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      <nav className=" px-5 md:px-10 pt-2 border-b-2">
        <div className="container flex flex-wrap justify-between items-center mx-auto px-5 md:px-10 pb-4 ">
          <a href="/" className="flex items-center">
            <img src={logo} alt="" width={150} />
          </a>
        </div>
      </nav>
      <div className="container justify-center mx-auto py-10 w-96 px-5">
        <p className="text-6xl text-center">Welcome</p>
        <p className="text-gray-500 text-lg font-medium text-center my-4">
          Verify the OTP sent to your email and phone number and create a new
          password
        </p>
        <form>
          <OtpField
            value={otp}
            onChange={(value) => {
              setOtpError(false);
              setOtp(value);
            }}
            numInputs={4}
            onChangeRegex={/^([0-9]{0,})$/}
            autoFocus
            separator={<span></span>}
            isTypeNumber
            hasError={otpError}
            inputProps={{ className: "otp-field__input", disabled: false }}
          />

          <PasswordField
            id="password"
            label="Password"
            required={true}
            value={password}
            error={passwordError}
            errorMessage="Password must contain at least 1 lowercase, 1 uppercase, 1 numeric, one special character and must be eight characters or longer"
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError(false);
            }}
          />
          <PasswordField
            id="confirmPassword"
            label="Confirm Password"
            required={true}
            value={confirmPassword}
            error={confirmPasswordError}
            errorMessage="Passwords do not match"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setConfirmPasswordError(false);
            }}
          />
          <Button
            onClick={validateFields}
            disabled={false}
            isLoading={loading}
            label="Create Password"
          />
        </form>
      </div>
    </div>
  );
}
