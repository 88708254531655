import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutlineButton from "../OutlineButton";
import DangerButton from "../DangerButton";
import Button from "../Button";
import StaffAPI from "../../apis/staffAPI";

export default function StaffModal({ show, toggle, data }) {
  let navigate = useNavigate();
  const [statusLoading, setStatusLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {}, [refresh]);

  const toggleStaffStatus = async () => {
    setStatusLoading(true);
    try {
      let response = await StaffAPI.toggleStaffStatus({
        enabled: !data?.isActive,
        id: data?._id,
      });
      Toast.success(response.message);
      data.isActive = !data.isActive;
      setRefresh(!refresh);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setStatusLoading(false);
    }
  };

  const deleteStaff = async () => {
    setDeleteLoading(true);
    try {
      let response = await StaffAPI.deleteStaff(data?._id);
      Toast.success(response.message);
      setTimeout(() => toggle(true), 1000);
    } catch (err) {
      Toast.error(err.response.data.message);
      navigate("/login");
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div
      className={
        show
          ? "w-full bg-black bg-opacity-20 fixed z-30 inset-0"
          : "hidden w-full bg-black bg-opacity-20 fixed z-30 inset-0"
      }
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        id="staticModal"
        data-modal-backdrop="static"
        tabindex="-1"
        aria-hidden="true"
        className="max-w-2xl mt-20 overflow-y-auto overflow-x-hidden fixed z-50 p-4 w-full md:inset-0 h-modal mx-auto md:h-full"
      >
        <div className="relative w-full max-w-2xl h-full md:h-auto">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow">
            {/* Modal header */}
            <div className="flex items-start p-4 rounded-t border-b">
              <div className="grow">
                <img
                  className="w-32 h-32 rounded-full mx-auto"
                  src={data?.imageUrl}
                  alt={data?.fullName}
                />
              </div>
              <button
                onClick={() => toggle()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-red-500 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                data-modal-toggle="staticModal"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="px-6 py-4">
              <div className="md:grid grid-cols-2 gap-x-5 gap-y-2">
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">First Name: </p>
                  <p className="font-medium">{data?.firstName}</p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">Last Name: </p>
                  <p className="font-medium">{data?.lastName}</p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">Email:</p>
                  <p className="font-medium">{data?.email}</p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">Phone Number:</p>
                  <p className="font-medium">{data?.phoneNumber}</p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">Address:</p>
                  <p className="font-medium">
                    {data?.address?.fullAddress ?? "Nill"}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">Status:</p>
                  <p className="font-medium">
                    {data?.isActive ? "Active" : "Not active"}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-gray-500 text-base mr-1">Total Pickups:</p>
                  <p className="font-medium">{data?.totalRequests}</p>
                </div>
              </div>
              <div className="flex space-x-5">
                {data?.isActive ? (
                  <OutlineButton
                    disabled={false}
                    isLoading={statusLoading}
                    label="Disable Staff"
                    onClick={toggleStaffStatus}
                  />
                ) : (
                  <Button
                    disabled={false}
                    isLoading={statusLoading}
                    label="Activate Staff"
                    onClick={toggleStaffStatus}
                  />
                )}
                <DangerButton
                  disabled={false}
                  isLoading={deleteLoading}
                  label="Delete Staff"
                  onClick={deleteStaff}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
